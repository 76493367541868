// src/redux/slices/bookSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clickedBooks: [], // Initial state is an empty array
};

const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    addBook: (state, action) => {
      state.clickedBooks.push(action.payload); // Mutates state directly
    },
  },
});

export const { addBook } = bookSlice.actions;

export default bookSlice.reducer;
