import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import './all.css';
import { IoStar, IoStarOutline } from "react-icons/io5";
import ButtonCustom from '../components/ButtonCustom';
import { Rating } from '@mui/material';
import BookCardTem from '../components/BookCardtem/BookCardTem';
import { saveAs } from 'file-saver';
import 'react-toastify/dist/ReactToastify.css';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import FormUserNotLogin from '../components/form/FormUserNotlogin';
import axios from 'axios';
import { RxSlash } from "react-icons/rx";

const SeriesBookDetails = () => {
 
  const [user, setUser] = useState(null);
  const [relatedbooks ,setrelatedbooks] = useState([])
  const location = useLocation();
  const [userId, setuserId] = useState('');
  const [token, setToken] = useState('');
  const { data } = location.state || {};
  const [showFullText, setShowFullText] = useState(false);
  const navigation = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const storedData = localStorage.getItem('user');
  const [isloading,setisLoading] = useState(false)
  const [checkplan,setcheckplan] = useState()
  const [bookdata,setbookdata] = useState({})
  const tokensas = localStorage.getItem('token');
  useEffect(() => {
    apiFatchBook(data?.books[0]._id)
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const userId = parsedData.user?._id;
      const TOKEN = parsedData.loginToken;
      setuserId(userId);
      setToken(TOKEN);
    } else {
      console.log('No data found in local storage.');
    }
  }, []);
  const fetchBookscheckPayment= async () => {
    if (!userId) return;
    try {
        const response = await axios.get(`https://new-app-testing-2d30280db142.herokuapp.com/api/check-payment-plan/${userId}`);
        setcheckplan(response.data)
  
    } catch (error) {
        console.error('Error fetching books:', error);
    }
  };
  const fetchrelatedbooks = async () => {
    const checktoken = localStorage.getItem('token');
    try {
      setisLoading(true);
      const response = await axios.get(`https://backend.mysecretlit.com/api/book/getRelatedSeries?seriesId=${data._id}&bookId=${data?.books[0]?._id}`,{
        headers: {
          Authorization: `Bearer ${checktoken}`,
        },
      });
      // const relatedBooksData = response.data.data;
      // const filteredBooks = relatedBooksData.filter(book => book._id !== data._id).slice(0, 6);

      setrelatedbooks(response.data.data);
    }
     catch (error) {
      console.error('Error fetching books:', error);

    }finally{
      setisLoading(false);
    }
  };
  const apiFatchBook = async (id) => {
try {
const response = await axios.get(`https://backend.mysecretlit.com/api/user/getBookById?id=${id}`)  
setbookdata(response.data.data)
localStorage.setItem('pdfurl',response.data.data.pdfUrl)
} catch (error) {
  console.log('error',error)
}
  }
  const handleread = async () => {
    // navigation('/book-reading',{state:{data:bookdata?.pdfUrl}})
    try {
            const response = await axios.post('https://backend.mysecretlit.com/api/user/readBook', {bookId:data?._id}, {
              headers: {
                Authorization: `Bearer ${tokensas}`,
              },
            });
            if (response.data) {
              console.log('asdasdreadred',response.data)
            } else {
             
            }
          }
           catch (error) {
            if (error.response) {
              console.log(error.response.data);
            } else if (error.request) {
              console.log('Server not responding');
            } else {
              console.log('Unexpected error occurred');
            }
          }
    // if (user) {
    //   if (user) {
    //     navigation('/book-reading',{state:{data:data?.pdfUrl}})
    //     try {
    //       const response = await axios.post('https://backend.mysecretlit.com/api/user/readBook', {bookId:data?._id}, {
    //         headers: {
    //           Authorization: `Bearer ${tokensas}`,
    //         },
    //       });
    //       if (response.data) {
    //         console.log('asdasdread',response.data)
    //       } else {
           
    //       }
    //     }
    //      catch (error) {
    //       if (error.response) {
    //         console.log(error.response.data);
    //       } else if (error.request) {
    //         console.log('Server not responding');
    //       } else {
    //         console.log('Unexpected error occurred');
    //       }
    //     }
    //   } else {
    //     navigation('/get-plans');
    //   }
    // } else {
    //   setModalShow(true);
    // }
    console.log('worling')
  };

  const toggleShowText = () => {
    setShowFullText(!showFullText);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchrelatedbooks()
 
  }, []);

  useEffect(() => {
    if (userId) {
      fetchBookscheckPayment();
    }
  }, [userId]);
console.log('data?.pdfUrl',bookdata?.pdfUrl)
  const DownloadPDF = () => {
    saveAs(bookdata?.pdfUrl|| data?.pdfUrl, data.bookTitle);
  };

  const handleDownloadMOBI = async () => {
    const mobiUrl = bookdata?.kindleMobiUrl || data.kindleMobiUrl;
    if (mobiUrl) {
      const link = document.createElement('a');
      link.href = mobiUrl;
      link.download = 'MobiBook.mobi';
      link.click();
    } else {
      console.log('MOBI URL not found');
    }
  };

  const handleDownloadEPUB = async () => {
    const epubUrl = bookdata?.epubUrl || data?.epubUpload;
    const link = document.createElement('a');
    link.href = epubUrl;
    link.download = 'EpubBook.epub';
    link.click();
  };
  const handleDownload = (format) => {
    const user = localStorage.getItem('user');
    if (format === 'pdf') {
      DownloadPDF();
    } else if (format === 'epub') {
      handleDownloadEPUB();
    } else if (format === 'mobi') {
      handleDownloadMOBI();
    }
    // if (user) {
    //   if (checkplan?.paymentPlanId && checkplan?.paymentPlanId) {
    //     if (format === 'pdf') {
    //       DownloadPDF();
    //     } else if (format === 'epub') {
    //       handleDownloadEPUB();
    //     } else if (format === 'mobi') {
    //       handleDownloadMOBI();
    //     }
    //   } else {
    //     navigation('/get-plans');
    //   }
    // }
  };
  const handleBookClick = (bookData) => {
    console.log('bookDatabookData',bookData)
    window.scrollTo(0, 0);
    // window.location.reload()
    navigation('/book-details/you-may-also-like', { state: { data: bookData,Bookrender:data._id } });
  };
  const HeartFuntion = async(item)=>{
    const checktoken = localStorage.getItem('token');
    try {
        if (item) {
            const res = await axios.post('https://backend.mysecretlit.com/api/user/favBook',{bookId:item}, {
                headers: {
                    Authorization: `Bearer ${checktoken}`,
                },
            });
            if (res.status === 200) {
               
            } else {
                console.log('Failed to delete book. Please try again.');
            }
        }
    } catch (error) {
        console.error('Error making API call:', error);
    }
    }
  return (
    <div className="booking-detils">
      <div className="main-div-detils">
        <div className="row-detials">
          <div className="colmTwo imgcolm">
            <img src={data?.books[0].coverImage || bookdata?.coverImage} alt="" className='image-detils' />
          </div>
          <div className="colmTwo" style={{ textAlign: 'left' }}>
            <div className="text-heading">
              <h3 className="heaingDetailsMain">{data?.books[0].title || bookdata?.title}</h3>
            </div>
            <h6 className="author-name-class">Author Name: {data?.books[0].authorName || bookdata?.authorName}</h6>
            <div style={{ textAlign: 'left', width: '80%', color: 'white' }}>
            <span style={{padding:0}} className='first' id='bookdetails-size'>{data?.books[0].category?.name || bookdata?.category?.name}</span><span className='line-slash' style={{position:'relative'}}><RxSlash  style={{color:'white',fontSize:15,marginTop:'-2px'}}  /></span><span id='bookdetails-size' style={{padding:0}} className='Second'>{data.books[0]?.secondaryCategory?.name || bookdata?.secondaryCategory?.name}</span>
            </div>
            <p className='reviewDiv'>Reviews</p>
            <div className="rating-diiv">
            <Rating
  name="half-rating-read"
  defaultValue={data.books[0]?.rating || bookdata?.rating}
  precision={0.5}
  readOnly
  style={{fontSize:'19px'}}
  emptyIcon={<IoStar style={{ color: 'grey' }} fontSize="inherit" />}
/>            
              <p className="ratingTextdetails">({data.books[0]?.rating || bookdata?.rating})</p>
            </div>
          </div>
        </div>
        <div className="description-div">
          <h2 className='discription-heading-h2'>Description</h2>
          <div className="row-description-readmore">
            <p className={`description-para ${showFullText ? 'show' : 'hide'}`}>
              {data.books[0]?.longDescription || bookdata?.longDescription}
            </p>
            <button style={{ border: 'none' }} onClick={toggleShowText} className="read-more-btn">
              {showFullText ? 'Read Less' : 'Read More'}
            </button>
          </div>
          {(data.books[0]?.triggerWarningText || bookdata?.triggerWarningText) && (
  <h5 className='h5-heading-trigger-warning'>
    Trigger Warning <span style={{color:'#EE2D2F'}}>!!</span>
    <span className='description-para hide'>
      {data.books[0]?.triggerWarningText || bookdata?.triggerWarningText}
    </span>
  </h5>
)}          <div className="ButtonDivPdf">
            <div className="colm-pdfDetils">
              <NavLink style={{textDecoration:'none'}} 
                to={{
        pathname: '/book-reading',
        state:{data:bookdata?.pdfUrl}}} 
       
      onClick={handleread} // Trigger handleread on click
        >
              <ButtonCustom Text="Read"  />
              </NavLink>
              {/* <ButtonCustom Text="Read" OnclickHandle={() => handleread()} /> */}
            </div>
            <div className="colm-pdfDetils-new">
              {/* <FormUserNotLogin setUser={setUser} show={modalShow} onHide={() => setModalShow(false)} /> */}
              <div>
                {[DropdownButton].map((DropdownType, idx) => (
                 
                  <DropdownType
  className='btn-headercustom-transparet'
  as={ButtonGroup}
  key={idx}
  id={`dropdown-button-drop-${idx}`}
  size="sm"
  variant="secondary"
  title="Download"
>
  {/* EPUB Download */}
  {(data?.books[0].epubUrl || bookdata?.epubUrl) && (
    <Dropdown.Item 
      style={{ background: 'black' }} 
      onClick={() => handleDownload('epub')} 
      eventKey="1"
    >
      EPUB
    </Dropdown.Item>
  )}
  
  {/* PDF Download */}
  {(data?.books[0].pdfUrl || bookdata?.pdfUrl) && (
    <Dropdown.Item 
      style={{ background: 'black' }} 
      onClick={() => handleDownload('pdf')} 
      eventKey="2"
    >
      PDF
    </Dropdown.Item>
  )}

  {/* MOBI Download */}
  {(bookdata?.kindleMobiUrl || data?.books[0].kindleMobiUrl) && (
    <Dropdown.Item 
      style={{ background: 'black' }} 
      onClick={() => handleDownload('mobi')} 
      eventKey="3"
    >
      MOBI
    </Dropdown.Item>
  )}
</DropdownType>

                ))}
              </div>
            </div>
          </div>
          {relatedbooks?.length > 0 && (
          <div className="releatedBooks">
            <h2 className='discription-heading-h2 details-padding' style={{paddingLeft:0}}>You May Also Like</h2>
            <div className='relaed-book-show-gap'>
              
              {relatedbooks?.map((card, index) => (
                <div key={index} className='custom-deatils-card' onClick={()=>handleBookClick(card)}>
                  <BookCardTem
                  onHeartClick={()=>HeartFuntion(card?._id)}
                  color={false}
                    Bookname={card?.bookTitle}
                    firstTopTag={card?.FirstTopTag}
                    secTopTag={card?.SecTopTag}
                    bookcategoriesFirst={card?.primaryCategory?.name}
                    bookcategoriesSec={card?.secondaryCategory?.name}
                    rating={card?.rating}
                    bookImg={card?.coverImage}
                    loading={isloading}
                  />
                </div>
              ))}
            </div>
          </div>)}
        </div>
      
      </div>
    </div>
  );
};

export default SeriesBookDetails
