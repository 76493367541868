import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'antd/dist/reset.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import store from './redux/Store';
import { GoogleOAuthProvider } from "@react-oauth/google"

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
     <GoogleOAuthProvider clientId='719415395030-4313nn5clel03gitpfidt5a3o39iuafm.apps.googleusercontent.com'>
     <Provider store={store}>
     <App />
     </Provider>
     </GoogleOAuthProvider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
