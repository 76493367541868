import React, { useEffect} from 'react'
import './all.css'
import Banner from './homesaleBanner/Banner';
import Plains from '../components/plains/Plains';

const GetVipsPlain = () => {

   
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
    <div className="booking-detils">
        <div className="main-div-detils addVip">

        <div className="description-div" style={{paddingTop:0}}>
<h2 className='discription-heading-h2 getVip'>Select a payment plan</h2>
<Banner color={true} showIcon={false} cursor={false}/> 
</div>
<div className="description-div" style={{paddingTop:35}}>
        <div className="viptextHeadingDiv">
            <h2 className="discriptionGetVipp" style={{paddingTop:0}}>Membership Now</h2>
        </div>
        <Plains/> 
</div>
        </div>
    </div>
  )
}

export default GetVipsPlain