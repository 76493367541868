import './App.css';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import PageNotFound from './pages/PageNotFound';
import Header from './header/Header'
import Series from './pages/Series';
import NewPage from './pages/NewPage';
import AudioBooks from './pages/auido/AudioBooks';
import Footer from './footer/Footer';
import BookDetails from './pages/BookDetails';
import GetVipPlains from './pages/GetVipPlains';
import CardOption from './pages/cardoption/CardOption';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermAndConditon from './pages/TermAndConditon';
import RecentRead from './pages/accountSetting/RecentRead';
import PurchaseHistory from './pages/accountSetting/PurchaseHistory';
import AccountSetting from './pages/accountSetting/AccountSetting';
import PromoCodes from './pages/accountSetting/PromoCodes';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderRisponsive from '../src/header/ResponsiveHeader'
import { useEffect, useState } from 'react';
import Genres from './pages/Genres';
import { NavLink } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Loading from '../src/pages/loader/Loader'
import ParticulargenresPage from '../src/pages/Particulargenrepage'
import CencelMemberShip from './pages/accountSetting/CencelMemberShip';
import DetailspageCAta from './components/particulardropdwoncategoryDetails/DetailsPageCat';
import AllAudioBooks from './pages/auido/AllAudioBooks';
import CustomerService from './pages/accountSetting/customerServices/CustomerServices';
import IsFavBooks from './pages/accountSetting/IsFavbooks';
import TestData from './pages/TestData';
import SearchResultsPage from './pages/SearchResultsPage';
import ResponsiveSearchPg from './pages/ResponsiveSearchpg';
import SeriesBookDetails from './pages/SeriesBookDetails';
import YouMakeAlsoLike from './pages/YouMayalsoLike';
function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // 3 seconds delay

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
     {isLoading ? <Loading /> : 
      <div className="App" onClick={closeMenu}>
     
      <BrowserRouter>
      <HeaderRisponsive  closedMenu={closeMenu}isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
  <Header/>
  
  <Routes >
    <Route path="/"  element={<Home/>} />
    <Route path="/book-reading"  element={<TestData/>} />
    <Route path='/Series/book-details'  element={<SeriesBookDetails/>}/>
    <Route path='/book-details/you-may-also-like'  element={<YouMakeAlsoLike/>}/>

    <Route path='/Series'  element={<Series/>}/>
    <Route path="/AudioBooks/details"  element={<AudioBooks/>} />
    <Route path="/mypage/customer-services"  element={<CustomerService/>} />
    <Route path="/AudioBooks"  element={<AllAudioBooks/>} />
    <Route path='/New'  element={<NewPage/>}/>
    <Route path='/search-results'  element={<SearchResultsPage/>}/>
    <Route path='/BookDetails' element={<BookDetails/>}/>
    <Route path='/get-plans'  element={<GetVipPlains/>}/>
    <Route path= 'Contact' element={<Contact/>}/>
    <Route path= '/get-plans/creditoption' element={<CardOption/>}/>
    <Route path= '/privacy-policy' element={<PrivacyPolicy/>}/>
    <Route path= '/term-and-condition' element={<TermAndConditon/>}/>
    <Route path= '/cancelMembership' element={<CencelMemberShip/>}/>
    <Route path= 'mypage/RecentRead' element={<RecentRead/>}/>
    <Route path= 'mypage/is-favorite-books' element={<IsFavBooks/>}/>
    <Route path= 'mypage/PurchaseHistory' element={<PurchaseHistory/>}/>
    <Route path= 'mypage/accountSetting' element={<AccountSetting/>}/>
    <Route path= 'mypage/promotion_code' element={<PromoCodes/>}/>
    <Route path="Genres/:categoryname" element={<DetailspageCAta/>} />
    <Route path="/Genremobile" element={<Genres/>} />
    <Route path="/search-books" element={<ResponsiveSearchPg/>} />
    
    <Route path="*" element={<PageNotFound/>} />
  </Routes>
 <Footer/>
  </BrowserRouter>
    </div>
  }
    </>
    
  );
}

export default App;
