// import React from 'react'
// import './CaroselHome.css'
// import ButtonCustom from '../ButtonCustom'
// // import ImageBook from '../../assets/images/firstBook.png'
// const TextBanner = ({Heading,RedTegHeading,Details,ImageBook,BtnClickhandle}) => {
//   return (
//     <div className='main-heading-div'>
//         <div className="rowTextbannner">
//             <div className="colm-CaroselHome">
//              <h1 className="textHeaderBanner" style={{cursor:'pointer'}}>
//             {Heading} <span style={{color:'#EE2D2F'}}>{RedTegHeading}</span>
//              </h1>
//              <p className="paragrph-header-banner" style={{cursor:'pointer'}}>
//            {Details}
//              </p>
//              <div className='readmore-btn-div'>
//              <ButtonCustom Text="Read more" OnclickHandle={BtnClickhandle}/>
//              </div>
            
//             </div>
//             <div className="colm-CaroselHome addtion" style={{cursor:'pointer'}}>
//                 <img src={ImageBook} alt="" srcset="" className='banner-image-img' />
//             </div>
//         </div>
//     </div>
//   )
// }

// export default TextBanner
import React from 'react';
import './CaroselHome.css';
import ButtonCustom from '../ButtonCustom';

const TextBanner = ({ Heading, RedTegHeading, Details, ImageBook, BtnClickhandle }) => {
  return (
    <div className='main-heading-div'>
      <div className="rowTextbannner">
        <div className="colm-CaroselHome">
          <h1 className="textHeaderBanner" style={{cursor: 'pointer'}} onClick={BtnClickhandle}>
            {Heading} <span style={{color: '#EE2D2F'}}>{RedTegHeading}</span>
          </h1>
          <p onClick={BtnClickhandle} className="paragrph-header-banner" style={{cursor: 'pointer'}}>
            {Details}
          </p>
          <div className='readmore-btn-div'>
            <ButtonCustom Text="Read more" OnclickHandle={BtnClickhandle} />
          </div>
        </div>
        <div className="colm-CaroselHome addtion"  >
          <img src={ImageBook} alt="" className='banner-image-img' style={{cursor: 'pointer'}}  onClick={BtnClickhandle}/>
        </div>
      </div>
    </div>
  );
};

export default TextBanner;
