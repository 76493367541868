import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from 'react-bootstrap/Form';
import { IoIosArrowDown } from "react-icons/io";

const AccountSettingContact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [userId, setUserId] = useState('');
  useEffect(() => {
    const storedData = localStorage.getItem('user');
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        if (parsedData && parsedData.user && parsedData.user._id) {
          setUserId(parsedData.user._id);
        } else {
          console.error('User data or _id is missing');
        }
      } catch (error) {
        console.error('Error parsing user data:', error);
      }
    } else {
      console.log('No data found in local storage.');
    }
  }, []);

  const [name, setName] = useState('');
  const [subject, setSubject] = useState(''); // Initially empty to enforce validation
  const [message, setMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [subjectError, setSubjectError] = useState(''); // New state for subject error

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if subject is empty
    if (subject.trim() === '') {
      setSubjectError('Subject is required.');
      return; // Prevent form submission
    }

    setLoading(true);

    try {
      const response = await axios.post('https://new-app-testing-2d30280db142.herokuapp.com/api/add-refund-contact-form', {
        userId: userId, // Replace with actual userId
        name,
        subject,
        message
      });
      console.log(response.data)
      toast.success('Message sent successfully!');
      setName('');
      setSubject(''); // Reset subject to empty for validation
      setMessage('');
      setSubjectError(''); // Clear error after successful submission
    } catch (error) {
      toast.error('Error sending message. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setName(value);
    updateButtonState(value, subject, message);
  };

  const handleSubjectChange = (event) => {
    const value = event.target.value;
    setSubject(value);

    // Clear the error if a valid subject is selected
    if (value.trim() !== '') {
      setSubjectError('');
    }

    updateButtonState(name, value, message);
  };

  const handleInputChangeMessage = (event) => {
    const value = event.target.value;
    setMessage(value);
    updateButtonState(name, subject, value);
  };

  const updateButtonState = (name, subject, message) => {
    const isFilled = name.length > 0 && subject.length > 0 && message.length > 0;
    setIsButtonDisabled(!isFilled);
  };

  const buttonStyle = {
    backgroundColor: isButtonDisabled ? 'rgb(217 82 84)' : '#EE2D2F',
    color: 'white',
    cursor: isButtonDisabled ? 'default' : 'pointer',
    fontFamily: '"Noto Sans SC", sans-serif',
    padding: '7px 20px',
    width: '80%',
    borderRadius: 50,
    border: 'none',
    fontSize: 16
  };

  return (
    <div className="booking-detils" style={{height:'100%',background:'#393A3E'}}>
      <div className="contact-form-container" style={{background:'black'}}>
        <form onSubmit={handleSubmit}>
          {/* Name input field */}
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
            style={{background:'#f8f8f8',color:'black'}}
              type="text"
              id="name"
              value={name}
              onChange={handleInputChange}
              required
              placeholder='Name'
            />
          </div>
          
          {/* Subject dropdown */}
          <div className="classes-refund-dropdwon">
            <label htmlFor="subject" style={{ width: '100%', color: 'white', textAlign: 'left', marginBottom: 8 }}>Subject:</label>
            <Form.Select 
              className='form-new-refund' 
              style={{background:'#f8f8f8',color:'black'}}
              value={subject} 
              onChange={handleSubjectChange}
              required
            >
              <option value="">Select a Subject</option> {/* Placeholder option */}
              <option value="General Inquiry">General Inquiry</option>
              <option value="Customer Support">Customer Support</option>
              <option value="Book Recommendation">Book Recommendation</option>
              <option value="Feedback">Feedback</option>
              <option value="Refund">Refund</option>
            </Form.Select>
            <IoIosArrowDown className='icon-btn-gendrr' style={{ top: 41, color: 'black' }} />
            {subjectError && <div style={{ color: 'red', marginTop: 5 }}>{subjectError}</div>} {/* Error message */}
          </div>

          {/* Message input field */}
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
            //  style={{background:'#f8f8f8',color:'black'}}
              id="message"
              value={message}
              onChange={handleInputChangeMessage}
              style={{ color: 'white', marginBottom: 20,background:'#f8f8f8',color:'black' }}
              placeholder='Message'
            ></textarea>
          </div>
          
          {/* Submit button */}
          <div className='btn-contact-pg'>
            <button style={buttonStyle} disabled={isButtonDisabled || loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
        <ToastContainer pauseOnHover={false} autoClose={2000} hideProgressBar={true} />
      </div>
    </div>
  );
};

export default AccountSettingContact;
