import React, { useEffect } from 'react'
import './all.css'
import { NavLink } from 'react-router-dom';
const PrivacyPolicy = ({width}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <div className="booking-detils">
    <div className='termConditon-main' style={{width:width}}>
    <h1 className='discription-heading-h2 term'>Privacy & Policy </h1>
    <p className="term-and-condition">
    MySecretLit managed by Innovative Byte Solutions, (hereinafter“MySecretLit”, “the company”,“Msl”"We", "Us" , "Our") values your privacy rights and protects the personal information collected, stored, and used in connection with MySecretLit Service ("Service"). Hence, we developed a Privacy Policy that covers how we collect, store and use your information.
    </p>
    <h6 className="tag-term">What & How We Collect Information</h6>
    <p className="term-and-condition" style={{paddingTop:0,fontWeight:500}}>
    What We Collect
    </p>
    <p className="term-and-condition" style={{paddingTop:0}}>
    - When creating an account - <br></br> Essential information needed to create an account such as social ID, Nickname, e-email, encoded password and etc.
    </p>
    <p className="term-and-condition" style={{paddingTop:0}}>
    - When making a purchase - <br></br> For credit & Debit card : credit/debit card company, card number and etc.
    </p>
    <p className="term-and-condition" style={{paddingTop:0}}>
    - When using the Service - <br></br> Means of register, date of register, IP Address, cookie, date of visit, service usage record, malfunction record, device information and etc.
    </p>
    
    <h6 className="tag-term">How We Collect</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    We collect your information in the following way.<br></br>
    - Through our webpage, call, customer service, e-mail, events and promotions <br></br>
    - Provided by partner companies <br></br>
    - Through data collection tools 
    </p> 
    <h6 className="tag-term">Compensation Policy for Service Failure</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    We collect information when you use our Service to provide, maintain, improve, develop our Service, to track and block illegal users, to protect the Service users, and to provide advertisements with high relevance to users.
    </p> 
    <h6 className="tag-term">Sharing & Provision of Personal Information</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    We use personal information of users within the scopes mentioned in "Collecting Personal Information & Its Purpose". We do not use personal information of users beyond those scopes without the consent of users, or share with external parties. However, for social log-in, we may share information in order to process social log-in.
    </p>
    <h6 className="tag-term">Consignment of Personal Information Processing</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    We consign personal information to the following companies to improve our Service. We have clearly stipulated in a consignment agreement according to related laws to safely manage personal information.<br></br> The following are consignees and their purpose.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0}}>
      - Consignee  <br></br>
      - Entrusted Task <br></br>  
      - Personal Information Retention and Use Period <br></br>
      - Trusted Payment Gateways <br></br> 
      - Processing Transactions  <br></br>
      - Until the account is terminated or termination                                                               
                                                                                                   of the consignment contract
    </p>
    <h6 className="tag-term">Personal Information Retention & Use Period</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    In principle, we immediately destroy the personal information of users once the purpose of personal information collection is achieved. However, certain information will be retained up to 5 years for management purposes.
    </p> 
    <p className="term-and-condition" style={{paddingTop:0,fontWeight:500}}>
Company Policy for Retention of Personal Information <br></br>
<span style={{fontWeight:300}}>- Illegal Uses (Record of abnormal service usages such as illegal sign ups)</span><br></br>
<span style={{fontWeight:300}}>Items retained: e-mail used for sign up</span> <br></br>
<span style={{fontWeight:300}}>Purpose of retention: to prevent illegal sign up and usage Period of retention : 1 year</span>
    </p>
    <h6 className="tag-term">Technical and Administrative Security for Personal Information</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    We aim to maintain technical and administrative securities for your personal information to prevent loss, theft, leakage, alteration, or damage of your personal information.
    </p>
    <p className="term-and-condition" style={{paddingTop:0,fontWeight:500}}>
    Encoding Passwords <br></br>
<span style={{fontWeight:300}}>MySecretLit users' passwords are encoded, and therefore only you know the password. Only you can access and change personal information.</span>
    </p> 
    <p className="term-and-condition" style={{paddingTop:0,fontWeight:500}}>
    Measures Against Hacking <br></br>
<span style={{fontWeight:300}}>We aim to protect your personal information from hacking and computer viruses to prevent leakage or damage of personal information.</span><br></br>
<span style={{fontWeight:300}}>To prevent your personal information from being damaged, we back-up our data regularly, and transfer personal information through encoded networks.</span> <br></br>
<span style={{fontWeight:300}}>We take measures to stop and monitor breaches by using a firewall system and other technology.</span>
    </p>
    <h6 className="tag-term">Disputes</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    We will take appropriate measures to resolve any issue, claim, or dispute between you and MySecretLit that arises in whole or in part from the Service. If the dispute between you and MySecretLit is not resolved, it will be submitted, commenced, and be heard in "Dispute Resolution Law Firm” in either Pakistan or Netherlands. The laws of the country will govern any dispute of any sort that might arise between you and MySecretLit, except for the case when the unresolved issue, claim, or dispute between you and MySecretLit related or connected to your payment processed by Payment Gateway. In this case, the resolution of your issue, claim, or dispute will be governed by the laws of the country payment gateway is based in.
    </p>
    <h6 className="tag-term">Remove your data from MySecretLit</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    If you want to delete all your personal data MySecretLit has stored on you, <br></br>
    Please send a request email to ContactUs@MySecretLit.com including your name, email address and phone number. <br></br>
    We will respond promptly within 7 days of receiving your request for deletion of personal data.
    </p>
    <h6 className="tag-term">Contact Information Regarding Privacy Policy</h6>
    <p className="term-and-condition" style={{paddingTop:0}}>
    If you have any questions regarding the Privacy Policy, please e-mail us at <NavLink to='/Contact'> ContactUs@MySecretLit.com</NavLink>
    </p>
    <p className="term-and-condition" style={{paddingTop:0,fontWeight:500}}>
    Provision </p>
    <p className="term-and-condition" style={{paddingTop:0,fontWeight:500}}>
    These Terms were last modified on Sep 23, 2024 </p>

    </div>
    </div>
  )
}

export default PrivacyPolicy