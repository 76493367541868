// import React, { useEffect, useState } from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { Pagination } from '@mui/material';
// import BookCardTem from '../components/BookCardtem/BookCardTem';
// import { Oval } from 'react-loader-spinner'; // Import the spinner component
// import './all.css';

// function Genres() {
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [books, setBooks] = useState([]); // Initialize as an empty array
//   const [currentPage, setCurrentPage] = useState(1);
//   const [loading, setLoading] = useState(true); // Add loading state
//   const [isLoading ,setisLoading] =useState(true)
//   const itemsPerPage = 48;
//   const navigate = useNavigate();
//   const fetchCategories = async () => {
//     try {
//       const response = await axios.get('https://backend.mysecretlit.com/api/user/getGenres?type=test');
//       const formattedData = response.data.data.map((item) => ({
//         ...item,
//         id: item._id // Use '_id' or another unique property as 'id'
//       }));
//       setCategories(formattedData);
//       if (formattedData.length > 0) {
//         setSelectedCategory(formattedData[0]);
//       }
//       setLoading(false); // Set loading to false after data is fetched
//     } catch (error) {
//       console.error('Error fetching categories:', error);
//       setLoading(false); // Set loading to false in case of error
//     }
//   };

//   const fetchBooksByCategory = async (categoryId) => {
//     try {
//       setisLoading(true); // Set loading to true before fetching books
//       const response = await axios.get(`https://backend.mysecretlit.com/api/user/getBookByCategory?categoryId=${categoryId}`);
//       setBooks(response.data.data || []); // Ensure books is an array
     
//     } catch (error) {
//       console.error('Error fetching books:', error);
//       // Set loading to false in case of error
//     }finally{
//       setisLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchCategories();
//   }, []);

//   useEffect(() => {
//     if (selectedCategory) {
//       fetchBooksByCategory(selectedCategory.id);
//     }
//   }, [selectedCategory]);

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//     window.scrollTo(0, 0);
//   };

//   const handleCategoryClick = (category) => {
//     setSelectedCategory(category);
//     setCurrentPage(1); // Reset to first page on category change
//   };

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = books?.slice(indexOfFirstItem, indexOfLastItem);

//   return (
//     <div>
//       {loading ? (
//         // Display spinner when loading is true
//         <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',background:'black'}}>
//           <Oval
//             height={80}
//             width={80}
//             color="red"
//             wrapperStyle={{}}
//             wrapperClass=""
//             visible={true}
//             ariaLabel='oval-loading'
//             secondaryColor="white"
//             strokeWidth={2}
//             strokeWidthSecondary={2}
//           />
//         </div>
//       ) : (
//         // Display content when loading is false
//         <>
//           <ul className='ul-classname'>
//             {categories?.map((category) => (
//               <li key={category.id}>
//                 <NavLink
//                   className={`category-link ${category.id === selectedCategory?.id ? 'activee' : 'noactivenavbar'}`}
//                   onClick={() => handleCategoryClick(category)}
//                 >
//                   {category.name}
//                 </NavLink>
//               </li>
//             ))}
//           </ul>
//           {selectedCategory && (
//             <div style={{ background: 'black',height:'100vh' }}>
//               <div className='seriesdiv-row'>
//               {(isLoading ? Array.from(new Array(18)) : currentItems)?.map((card, index) => (
//             <div key={index} style={{cursor:'pointer'}} className='custom-deatils-card' onClick={ () => navigate('/BookDetails', { state: { data: card } })}>
//             <BookCardTem
//               Bookname={card?.title}
//               firstTopTag={card?.FirstTopTag}
//               secTopTag={card?.SecTopTag}
//               bookcategoriesFirst={card?.category?.name}
//               bookcategoriesSec={card?.secondaryCategory?.name}
//               rating={card?.rating}
//               bookImg={card?.coverImage}
//               loading={isLoading}
//             />
//           </div>
//         ))}
//               </div>
//               <Pagination
//           count={Math.ceil(books.length / itemsPerPage)}
//           page={currentPage}
//           onChange={handlePageChange}
//           variant="outlined"
//         />
//             </div>
//           )}
//         </>
//       )}
//     </div>
//   );
// }

// export default Genres;

import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Pagination } from '@mui/material';
import BookCardTem from '../components/BookCardtem/BookCardTem';
import { Oval } from 'react-loader-spinner';
import './all.css';

function Genres() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [books, setBooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const itemsPerPage = 48;
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId')

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getGenres?type=test');
      const formattedData = response.data?.data?.reverse().map((item) => ({
        ...item,
        id: item._id
      }));
      setCategories(formattedData);
      if (formattedData.length > 0) {
        setSelectedCategory(formattedData[0]);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setLoading(false);
    }
  };

  const fetchBooksByCategory = async (categoryId) => {
    try {
      setIsLoading(true);
      let response 
      if (userId) {
        // Call the API with userId if it's present
        response = await axios.get(`https://backend.mysecretlit.com/api/user/getBookByCategory?categoryId=${categoryId}&userId=${userId}`)
      } else {
        // Call the API without userId if it's null or undefined
        response = await axios.get(`https://backend.mysecretlit.com/api/user/getBookByCategory?categoryId=${categoryId}`)
      }
      // const response = await axios.get(`https://backend.mysecretlit.com/api/user/getBookByCategory?categoryId=${categoryId}&userId=${userId}`);
      setBooks(response.data.data.reverse() || []);
    } catch (error) {
      console.error('Error fetching books:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchBooksByCategory(selectedCategory.id);
    }
  }, [selectedCategory]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = books?.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div>
      {loading ? (
        <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'black' }}>
          <Oval
            height={80}
            width={80}
            color="red"
            wrapperStyle={{}}
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="white"
            strokeWidth={2}
          />
        </div>
      ) : (
        <>
          <ul className='ul-classname'>
            {categories?.map((category) => (
              <li key={category.id}>
                <NavLink
                  className={`category-link ${category.id === selectedCategory?.id ? 'activee' : 'noactivenavbar'}`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category.name}
                </NavLink>
              </li>
            ))}
          </ul>
          {selectedCategory && (
            <div style={{ background: 'black', height: '100vh' }}>
              <div className='seriesdiv-row'>
                {(isLoading ? Array.from(new Array(18)) : currentItems)?.map((card, index) => (
                  <div key={index} style={{ cursor: 'pointer' }} className='custom-deatils-card'
                  //  onClick={() => navigate('/BookDetails', { state: { data: card } })}
                  onClick={() => {
                    if (card.series) {
                      navigate('/book-details/you-may-also-like', { state: { data: card,Bookrender:card._id } });
                    } else {
                      navigate('/BookDetails', { state: { data: card } });
                    }
                }}
                   >
                    <BookCardTem
                      Bookname={card?.title}
                      firstTopTag={card?.FirstTopTag}
                      secTopTag={card?.SecTopTag}
                      bookcategoriesFirst={card?.category?.name}
                      bookcategoriesSec={card?.secondaryCategory?.name}
                      rating={card?.rating}
                      bookImg={card?.coverImage}
                      loading={isLoading}
                    />
                  </div>
                ))}
              </div>
              {/* Render pagination only if there are 48 or more books */}
              {books.length >= 48 && (
                <Pagination
                  count={Math.ceil(books.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Genres;
