import Img from './assets/images/whiskeypoison.png'
import Img1 from './assets/images/jamie mcguire.png'
import Img2 from './assets/images/beautiful wedding.png'
import Img3 from './assets/images/heartless.png'
import Img4 from './assets/images/walking.png'
const getRandomImage = () => {
    const images = [Img1, Img2, Img3, Img4,Img];
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };
  export const BookingDetailsData = [
    {
      Bookname: "The Book Of Light",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Action',
      bookcategoriesSec: 'Romance',
      rating: '4.5',
      bookImg: Img3,
      authorName:'General Manoj Mukund Naravane'
    },
    {
      Bookname: "The Book new",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'BL',
      bookcategoriesSec: 'Thriller',
      rating: '4.9',
      bookImg: Img3,
      authorName:'General Manoj Mukund Naravane'
    },
    // Object 3
    {
      Bookname: "Another Book",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Adventure',
      bookcategoriesSec: 'Mystery',
      rating: '3.8',
      bookImg: Img3,
      authorName:'General Manoj Mukund Naravane'
    },
    // Object 4
    {
      Bookname: "The Mystery Book",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Mystery',
      bookcategoriesSec: 'Thriller',
      rating: '4.7',
      bookImg: Img3,
      authorName:'General Manoj Mukund Naravane'
    },
    {
      Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg: Img3,
      authorName:'General Manoj Mukund Naravane'
    },
    {
      Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg: Img3,
      authorName:'General Manoj Mukund Naravane'
    },
    ,
  ]
 export const CardDetails = [
    {
      Bookname: "The Book Of Light",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Action',
      bookcategoriesSec: 'Romance',
      rating: '4.1',
      bookImg: getRandomImage(),
      authorName:'General Manoj Mukund Naravane'
    },
    {
      Bookname: "Four Stars of Destiny",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'BL',
      bookcategoriesSec: 'Thriller',
      rating: '2.9',
      bookImg: getRandomImage(),
      authorName:'Satyajit Ray'

    },
    // Object 3
    {
      Bookname: "Another Book",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Adventure',
      bookcategoriesSec: 'Mystery',
      rating: '1.8',
      bookImg: getRandomImage(),
      authorName:'Yujeon Ju/ Auset | Ayase'
    },
    // Object 4
    {
      Bookname: "The Mystery Book",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Mystery',
      bookcategoriesSec: 'Thriller',
      rating: '3.7',
      bookImg: getRandomImage(),
      authorName:'Olga Tokarczuk'
    },
    // Object 5
    {
      Bookname: "Sci-Fi Adventure",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Sci-Fi',
      bookcategoriesSec: 'Adventure',
      rating: '2.2',
      bookImg: getRandomImage(),
      authorName:'Boria Majumdar'
    },
    // Object 6
    {
      Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg: getRandomImage(),
      authorName:'Saifuddin Soz'
    },
    // Object 7
    {
      Bookname: "Fantasy World",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Fantasy',
      bookcategoriesSec: 'Adventure',
      rating: '4.8',
      bookImg: getRandomImage(),
      authorName:'Fali S Nariman'
    },
    // Object 8
    {
      Bookname: "The Bestseller",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Bestseller',
      bookcategoriesSec: 'Thriller',
      rating: '4.6',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 9
    {
      Bookname: "Historical Fiction",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Historical',
      bookcategoriesSec: 'Fiction',
      rating: '4.3',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 10
    {
      Bookname: "The Adventure Series",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Adventure',
      bookcategoriesSec: 'Action',
      rating: '4.9',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 11
    {
      Bookname: "Detective Stories",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Mystery',
      bookcategoriesSec: 'Thriller',
      rating: '4.7',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 12
    {
      Bookname: "The Sci-Fi Thriller",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Sci-Fi',
      bookcategoriesSec: 'Thriller',
      rating: '4.6',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 13
    {
      Bookname: "Romantic Comedy",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Romance',
      bookcategoriesSec: 'Comedy',
      rating: '1.4',
      bookImg: getRandomImage(),
      authorName:'Anand Teltumbde'
    },
    // Object 14
    {
      Bookname: "The Drama Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Drama',
      bookcategoriesSec: 'Novel',
      rating: '1.4',
      bookImg: getRandomImage(),
      authorName:'	Devendra Fadnavis'
    },
    // Object 15
    {
      Bookname: "Biography of Legends",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Biography',
      bookcategoriesSec: 'History',
      rating: '1.8',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 16
    {
      Bookname: "The Thriller Series",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Thriller',
      bookcategoriesSec: 'Action',
      rating: '3.9',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 17
    {
      Bookname: "Classic Literature",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Literature',
      rating: '2.1',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 18
    {
      Bookname: "The Mystery Boxset",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Mystery',
      bookcategoriesSec: 'Boxset',
      rating: '3.6',
      bookImg: getRandomImage(),
      authorName:'Ramachandra Guha'
    },
    // Object 19
    {
      Bookname: "Adventure in Space",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Adventure',
      bookcategoriesSec: 'Space',
      rating: '1.4',
      bookImg: getRandomImage(),
      authorName:'Boria Majumdar'
    },
    // Object 20
    {
      Bookname: "The Bestseller Series",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Bestseller',
      bookcategoriesSec: 'Series',
      rating: '2.1',
      bookImg: getRandomImage(),
      authorName:'Amar Bhushan'

    },
    {
      Bookname: "The Book Of Light",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Action',
      bookcategoriesSec: 'Romance',
      rating: '4.1',
      bookImg: getRandomImage(),
      authorName:'General Manoj Mukund Naravane'
    },
    {
      Bookname: "Four Stars of Destiny",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'BL',
      bookcategoriesSec: 'Thriller',
      rating: '2.9',
      bookImg: getRandomImage(),
      authorName:'Satyajit Ray'

    },
    // Object 3
    {
      Bookname: "Another Book",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Adventure',
      bookcategoriesSec: 'Mystery',
      rating: '1.8',
      bookImg: getRandomImage(),
      authorName:'Yujeon Ju/ Auset | Ayase'
    },
    // Object 4
    {
      Bookname: "The Mystery Book",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Mystery',
      bookcategoriesSec: 'Thriller',
      rating: '3.7',
      bookImg: getRandomImage(),
      authorName:'Olga Tokarczuk'
    },
    // Object 5
    {
      Bookname: "Sci-Fi Adventure",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Sci-Fi',
      bookcategoriesSec: 'Adventure',
      rating: '2.2',
      bookImg: getRandomImage(),
      authorName:'Boria Majumdar'
    },
    // Object 6
    {
      Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg: getRandomImage(),
      authorName:'Saifuddin Soz'
    },
    // Object 7
    {
      Bookname: "Fantasy World",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Fantasy',
      bookcategoriesSec: 'Adventure',
      rating: '4.8',
      bookImg: getRandomImage(),
      authorName:'Fali S Nariman'
    },
    // Object 8
    {
      Bookname: "The Bestseller",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Bestseller',
      bookcategoriesSec: 'Thriller',
      rating: '4.6',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 9
    {
      Bookname: "Historical Fiction",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Historical',
      bookcategoriesSec: 'Fiction',
      rating: '4.3',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 10
    {
      Bookname: "The Adventure Series",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Adventure',
      bookcategoriesSec: 'Action',
      rating: '4.9',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 11
    {
      Bookname: "Detective Stories",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Mystery',
      bookcategoriesSec: 'Thriller',
      rating: '4.7',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 12
    {
      Bookname: "The Sci-Fi Thriller",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Sci-Fi',
      bookcategoriesSec: 'Thriller',
      rating: '4.6',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 13
    {
      Bookname: "Romantic Comedy",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Romance',
      bookcategoriesSec: 'Comedy',
      rating: '1.4',
      bookImg: getRandomImage(),
      authorName:'Anand Teltumbde'
    },
    // Object 14
    {
      Bookname: "The Drama Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Drama',
      bookcategoriesSec: 'Novel',
      rating: '1.4',
      bookImg: getRandomImage(),
      authorName:'	Devendra Fadnavis'
    },
    // Object 15
    {
      Bookname: "Biography of Legends",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Biography',
      bookcategoriesSec: 'History',
      rating: '1.8',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 16
    {
      Bookname: "The Thriller Series",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Thriller',
      bookcategoriesSec: 'Action',
      rating: '3.9',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 17
    {
      Bookname: "Classic Literature",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Literature',
      rating: '2.1',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 18
    {
      Bookname: "The Mystery Boxset",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Mystery',
      bookcategoriesSec: 'Boxset',
      rating: '3.6',
      bookImg: getRandomImage(),
      authorName:'Ramachandra Guha'
    },
    // Object 19
    {
      Bookname: "Adventure in Space",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Adventure',
      bookcategoriesSec: 'Space',
      rating: '1.4',
      bookImg: getRandomImage(),
      authorName:'Boria Majumdar'
    },
    // Object 20
    {
      Bookname: "The Bestseller Series",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Bestseller',
      bookcategoriesSec: 'Series',
      rating: '2.1',
      bookImg: getRandomImage(),
      authorName:'Amar Bhushan'

    },
    {
      Bookname: "The Book Of Light",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Action',
      bookcategoriesSec: 'Romance',
      rating: '4.1',
      bookImg: getRandomImage(),
      authorName:'General Manoj Mukund Naravane'
    },
    {
      Bookname: "Four Stars of Destiny",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'BL',
      bookcategoriesSec: 'Thriller',
      rating: '2.9',
      bookImg: getRandomImage(),
      authorName:'Satyajit Ray'

    },
    // Object 3
    {
      Bookname: "Another Book",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Adventure',
      bookcategoriesSec: 'Mystery',
      rating: '1.8',
      bookImg: getRandomImage(),
      authorName:'Yujeon Ju/ Auset | Ayase'
    },
    // Object 4
    {
      Bookname: "The Mystery Book",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Mystery',
      bookcategoriesSec: 'Thriller',
      rating: '3.7',
      bookImg: getRandomImage(),
      authorName:'Olga Tokarczuk'
    },
    // Object 5
    {
      Bookname: "Sci-Fi Adventure",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Sci-Fi',
      bookcategoriesSec: 'Adventure',
      rating: '2.2',
      bookImg: getRandomImage(),
      authorName:'Boria Majumdar'
    },
    // Object 6
    {
      Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg: getRandomImage(),
      authorName:'Saifuddin Soz'
    },
    // Object 7
    {
      Bookname: "Fantasy World",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Fantasy',
      bookcategoriesSec: 'Adventure',
      rating: '4.8',
      bookImg: getRandomImage(),
      authorName:'Fali S Nariman'
    },
    // Object 8
    {
      Bookname: "The Bestseller",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Bestseller',
      bookcategoriesSec: 'Thriller',
      rating: '4.6',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 9
    {
      Bookname: "Historical Fiction",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Historical',
      bookcategoriesSec: 'Fiction',
      rating: '4.3',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 10
    {
      Bookname: "The Adventure Series",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Adventure',
      bookcategoriesSec: 'Action',
      rating: '4.9',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 11
    {
      Bookname: "Detective Stories",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Mystery',
      bookcategoriesSec: 'Thriller',
      rating: '4.7',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 12
    {
      Bookname: "The Sci-Fi Thriller",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Sci-Fi',
      bookcategoriesSec: 'Thriller',
      rating: '4.6',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 13
    {
      Bookname: "Romantic Comedy",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Romance',
      bookcategoriesSec: 'Comedy',
      rating: '1.4',
      bookImg: getRandomImage(),
      authorName:'Anand Teltumbde'
    },
    // Object 14
    {
      Bookname: "The Drama Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Drama',
      bookcategoriesSec: 'Novel',
      rating: '1.4',
      bookImg: getRandomImage(),
      authorName:'	Devendra Fadnavis'
    },
    // Object 15
    {
      Bookname: "Biography of Legends",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Biography',
      bookcategoriesSec: 'History',
      rating: '1.8',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 16
    {
      Bookname: "The Thriller Series",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Thriller',
      bookcategoriesSec: 'Action',
      rating: '3.9',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 17
    {
      Bookname: "Classic Literature",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Literature',
      rating: '2.1',
      bookImg: getRandomImage(),
      authorName:'Devendra Fadnavis'
    },
    // Object 18
    {
      Bookname: "The Mystery Boxset",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Mystery',
      bookcategoriesSec: 'Boxset',
      rating: '3.6',
      bookImg: getRandomImage(),
      authorName:'Ramachandra Guha'
    },
    // Object 19
    {
      Bookname: "Adventure in Space",
      FirstTopTag: "End",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Adventure',
      bookcategoriesSec: 'Space',
      rating: '1.4',
      bookImg: getRandomImage(),
      authorName:'Boria Majumdar'
    },
    // Object 20
    {
      Bookname: "The Bestseller Series",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Bestseller',
      bookcategoriesSec: 'Series',
      rating: '2.1',
      bookImg: getRandomImage(),
      authorName:'Amar Bhushan'

    },
  ];
