import React from 'react'
import './form/Form.css'
const ButtonCustom = ({OnclickHandle,Text,Type}) => {
  return (
    <div className="">
 <button  type={Type}  className='btn-headercustom' onClick={OnclickHandle}>{Text}</button>
    </div>
   
  )
}

export default ButtonCustom
