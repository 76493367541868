import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import BookCardTem from "./BookCardTem";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

const AutoPlay = ({ CardDetails, loading, showDeleteIcon }) => {
  const [userId, setUserId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 5.5,
    slidesToScroll: 4,
    cssEase: "linear",
    centerMode: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
        }
      }
    ]
  };
  
  const navigate = useNavigate();
  const storedData = localStorage.getItem('user');

  useEffect(() => {
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const userId = parsedData.user?._id;
      setUserId(userId);
    } else {
      console.log('No data found in local storage.');
    }
  }, [storedData]);

  // Function to sort books
  const sortedCardDetails = () => {
    // Separate books with homeBanner: true
    const featuredBooks = CardDetails?.filter(book => book.categoryBanner);
    const regularBooks = CardDetails?.filter(book => !book.categoryBanner);
    
    // Combine them: featured books first, then regular books
    return [...featuredBooks, ...regularBooks];
  };

  const HeartFunction = async (bookId, isFav) => {
    const checktoken = localStorage.getItem('token');
    try {
      if (bookId) {
        if (!isFav) {
          // If the book is currently a favorite, delete it
          const res = await axios.delete(`https://backend.mysecretlit.com/api/user/deletefavBook?bookId=${bookId}`, {
            headers: {
              Authorization: `Bearer ${checktoken}`,
            },
          });
          if (res.status === 200) {
            console.log('Book removed from favorites successfully');
          } else {
            console.log('Failed to remove book from favorites. Please try again.');
          }
        } else {
          // If the book is not a favorite, add it
          const res = await axios.post('https://backend.mysecretlit.com/api/user/favBook', {
            bookId: bookId
          }, {
            headers: {
              Authorization: `Bearer ${checktoken}`,
            },
          });
          if (res.status === 200) {
            console.log('Book added to favorites successfully');
          } else {
            console.log('Failed to add book to favorites. Please try again.');
          }
        }
      }
    } catch (error) {
      console.error('Error making API call:', error);
    }
  };
  
  const handleBookClick = (bookData) => {
    navigate('/BookDetails', { state: { data: bookData } });
  };

  const openRefundDialog = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
  };

  const closeRefundDialog = () => {
    setOpenDialog(false);
  };

  const confirmRefund = async (id) => {
    const checkToken = localStorage.getItem('token');
    try {
      const res = await axios.delete(`https://backend.mysecretlit.com/api/user/deleteReadBook?bookId=${id}`, {
        headers: { Authorization: `Bearer ${checkToken}` },
      });
      if (res.status === 200) {
        window.location.reload();
      } else {
        console.log('Failed to delete book. Please try again.');
      }
    } catch (error) {
      console.error('Error making API call:', error);
    }
    console.log('recendt', id);
    closeRefundDialog();
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {(loading ? Array.from(new Array(5)) : sortedCardDetails())?.map((card, index) => (
          <div className="selectd-delete-btn" key={index} onClick={() => handleBookClick(card)} style={{ cursor: 'pointer' }}>
            {showDeleteIcon && (
              <MdDelete
                className="delete-icon-book"
                onClick={(event) => openRefundDialog(event)}
              />
            )}
           <BookCardTem
  Bookname={card?.bookTitle}
  firstTopTag={card?.FirstTopTag}
  secTopTag={card?.SecTopTag}
  bookcategoriesFirst={card?.category?.name}
  bookcategoriesSec={card?.secondaryCategory?.name}
  rating={card?.rating}
  bookImg={card?.coverImage}
  loading={loading}
  isFavorite={card?.isFav}
  onHeartClick={(newFavStatus) => HeartFunction(card?._id, newFavStatus)} // Pass new favorite status
/>

            <Dialog
              style={{ padding: '13px 24px' }}
              open={openDialog}
              onClose={(event) => { event.stopPropagation(); closeRefundDialog(); }}
              aria-labelledby="refund-dialog-title"
              aria-describedby="refund-dialog-description"
            >
              <DialogContent>
                <p className='reviewDiv poupparagrgtext'>
                  Are you sure you want to delete this book from Recently Read?
                </p>
              </DialogContent>
              <DialogActions style={{ display: 'flex', justifyContent: 'center', paddingBottom: 34 }}>
                <button className="btn-all-delete-popup" onClick={(event) => { event.stopPropagation(); confirmRefund(card._id); }} color="secondary" autoFocus>
                  Delete
                </button>
                <button className="btn-all-delete-popup" onClick={(event) => { event.stopPropagation(); closeRefundDialog(); }} color="primary">
                  Cancel
                </button>
              </DialogActions>
            </Dialog>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default AutoPlay;

// import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
// import BookCardTem from "./BookCardTem";
// import { useNavigate } from 'react-router-dom';
// import axios from "axios";
// import { MdDelete } from "react-icons/md";
// import { Dialog, DialogActions, DialogContent } from "@mui/material";

// const AutoPlay = ({ CardDetails, loading, showDeleteIcon }) => {
//   const [openDialog, setOpenDialog] = useState(false);
//   const [sortedBooks, setSortedBooks] = useState([]);
  
//   const settings = {
//     dots: false,
//     infinite: false,
//     slidesToShow: 5.5,
//     slidesToScroll: 4,
//     cssEase: "linear",
//     centerMode: false,
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 3.5,
//           slidesToScroll: 3,
//         }
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 3.5,
//           slidesToScroll: 3,
//         }
//       }
//     ]
//   };
//   const navigate = useNavigate();
//   useEffect(() => {
//     if (CardDetails) {
//       const homeBannerBooks = CardDetails.filter(book => book.homeBanner);
//       const otherBooks = CardDetails.filter(book => !book.homeBanner);
//       setSortedBooks([...homeBannerBooks, ...otherBooks]);
//     }
//   }, [CardDetails]);


//   const handleBookClick = (bookData) => {
//     navigate('/BookDetails', { state: { data: bookData } });

//   };

//   const openRefundDialog = (event) => {
//     event.stopPropagation();
//     setOpenDialog(true);
//   };

//   const closeRefundDialog = () => {
//     setOpenDialog(false);
//   };

//   const confirmRefund = async (id) => {
//     const checktoken = localStorage.getItem('token');
//     try {
//       const res = await axios.delete(`https://backend.mysecretlit.com/api/user/deleteReadBook?bookId=${id}`, {
//         headers: {
//           Authorization: `Bearer ${checktoken}`,
//         },
//       });
//       if (res.status === 200) {
//         window.location.reload();
//       } else {
//         console.log('Failed to delete book. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error making API call:', error);
//     }
//     closeRefundDialog();
//   };
//   const HeartFuntion = async(item)=>{
//     const checktoken = localStorage.getItem('token');
//     try {
//         if (item) {
//             const res = await axios.post('https://backend.mysecretlit.com/api/user/favBook',{bookId:item}, {
//                 headers: {
//                     Authorization: `Bearer ${checktoken}`,
//                 },
//             });
//             if (res.status === 200) {
               
//             } else {
//                 console.log('Failed to delete book. Please try again.');
//             }
//         }
//     } catch (error) {
//         console.error('Error making API call:', error);
//     }
//     }
//   return (
//     <div className="slider-container">
//       <Slider {...settings}>
//         {(loading ? Array.from(new Array(5)) : sortedBooks)?.map((card, index) => (
//           <div className="selectd-delete-btn" key={index} onClick={() => handleBookClick(card)} style={{ cursor: 'pointer' }}>
//             {showDeleteIcon && (
//               <MdDelete
//                 className="delete-icon-book"
//                 onClick={(event) => openRefundDialog(event)}
//               />
//             )}
//             <BookCardTem
//               Bookname={card?.bookTitle}
//               firstTopTag={card?.FirstTopTag}
//               secTopTag={card?.SecTopTag}
//               bookcategoriesFirst={card?.category?.name}
//               bookcategoriesSec={card?.secondaryCategory?.name}
//               rating={card?.rating}
//               bookImg={card?.coverImage}
//               loading={loading}
//               isFavorite={card?.isFavourite}
//               onHeartClick={()=>HeartFuntion(card?._id)}
//             />
//             <Dialog
//               open={openDialog}
//               onClose={(event) => { event.stopPropagation(); closeRefundDialog(); }}
//               aria-labelledby="refund-dialog-title"
//               aria-describedby="refund-dialog-description"
//             >
//               <DialogContent>
//                 <p className='reviewDiv poupparagrgtext'>
//                   Are you sure you want to delete this book from recently read?
//                 </p>
//               </DialogContent>
//               <DialogActions style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }}>
//                 <button className="btn-all-delete-popup" onClick={(event) => { event.stopPropagation(); confirmRefund(card._id); }} color="secondary" autoFocus>
//                   Delete
//                 </button>
//                 <button className="btn-all-delete-popup" onClick={(event) => { event.stopPropagation(); closeRefundDialog(); }} color="primary">
//                   Cancel
//                 </button>
//               </DialogActions>
//             </Dialog>
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// }

// export default AutoPlay;
