// import React, { useEffect, useRef, useState } from 'react';
// import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/webpack';
// import 'pdfjs-dist/web/pdf_viewer.css';
// import { Spinner } from 'react-bootstrap';
// GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${require('pdfjs-dist/package.json').version}/pdf.worker.min.js`;

// const PDFViewer = ({ url }) => {
//   const [loading, setLoading] = useState(true); // Loading state
//   const [numPages, setNumPages] = useState(0); // Number of pages in PDF
//   const [error, setError] = useState(null); // Error state
//   const canvasContainerRef = useRef(null); // Ref for the canvas container
//   const renderedPages = useRef(new Set()); // Set to track rendered pages

//   useEffect(() => {
//     if (!url) return; // Ensure URL is provided

//     const fetchPDF = async () => {
//       try {
//         const response = await fetch(url);
//         if (!response.ok) {
//           throw new Error(`Failed to fetch PDF: ${response.statusText}`);
//         }
//         const pdfData = await response.arrayBuffer();
//         const loadingTask = getDocument({ data: pdfData });
//         const pdf = await loadingTask.promise;
//         setNumPages(pdf.numPages);
//         setLoading(false); // Set loading to false when PDF is loaded

//         // Clear existing canvases (optional, if needed)
//         if (canvasContainerRef.current) {
//           canvasContainerRef.current.innerHTML = '';
//         }

//         // Render each page (optimized to prevent duplicate rendering)
//         for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
//           if (renderedPages.current.has(pageNum)) {
//             continue; // Skip rendering already rendered pages
            
//           }

//           const page = await pdf.getPage(pageNum);
//           const viewport = page.getViewport({ scale: 1.4 });
//           const canvas = document.createElement('canvas');
//           const context = canvas.getContext('2d');

//           canvas.height = viewport.height;
//           canvas.width = viewport.width;   


//           if (canvasContainerRef.current) {
//             canvasContainerRef.current.appendChild(canvas);
//           }

//           const renderContext = {
//             canvasContext: context,
//             viewport: viewport,
//           };

//           await page.render(renderContext).promise;
//           renderedPages.current.add(pageNum); // Mark page as rendered
//         }
//       } catch (err) {
//         console.error('Error loading PDF:', err);
//         setError('Failed to load PDF. Please check the URL or try again later.');
//         setLoading(false); // Stop loading if there's an error
//       }
//     };

//     fetchPDF();
//   }, [url]);

//   return (
//     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'black', minHeight: '100vh' }}>
//       {loading ? (
//         <Spinner animation="border" variant="light" /> // Spinner shown while loading
//       ) : error ? (
//         <div style={{ color: 'red' }}>{error}</div> // Display error message
//       ) : (
//         <div ref={canvasContainerRef} style={{ position: 'relative', width: '100%', overflow: 'auto' }} />
//       )}
//     </div>
//   );
// };

  
  
// const App = () => {
  
// const data = localStorage.getItem('pdfurl')
// const pdfUrl = data || "https://secret-life.s3.eu-north-1.amazonaws.com/files/1725628511037_Two_Twisted_Crowns.pdf";
// useEffect(()=>{
//   window.scrollTo(0, 0);
// })
//   if (!pdfUrl) {
//     return <div>No PDF URL found in location state.</div>;
//   }

//   return (
//     <div style={{ position: 'relative', background: 'black' }}>
//       <PDFViewer url={data} />
//     </div>
//   );
// };

// export default App;
import React, { useEffect, useRef, useState } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/webpack';
import 'pdfjs-dist/web/pdf_viewer.css';
import { Spinner } from 'react-bootstrap';

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${require('pdfjs-dist/package.json').version}/pdf.worker.min.js`;

const PDFViewer = ({ url }) => {
  const [loading, setLoading] = useState(true); // Loading state
  const [numPages, setNumPages] = useState(0); // Number of pages in PDF
  const [error, setError] = useState(null); // Error state
  const canvasContainerRef = useRef(null); // Ref for the canvas container
  const renderedPages = useRef(new Set()); // Set to track rendered pages

  // Function to render a page
  const renderPage = async (page, viewport, canvasContainer) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const retinaScale = 2; // Higher scale for better quality

    // High-quality rendering for both mobile and web
    canvas.height = viewport.height * retinaScale;
    canvas.width = viewport.width * retinaScale;
    canvas.style.width = `${viewport.width}px`; // Fit to screen
    canvas.style.height = `${viewport.height}px`;

    // Adjust the scale to get a high-quality image
    context.scale(retinaScale, retinaScale);

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    await page.render(renderContext).promise;

    if (canvasContainer) {
      canvasContainer.appendChild(canvas);
    }
  };

  useEffect(() => {
    if (!url) return; // Ensure URL is provided

    const fetchPDF = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch PDF: ${response.statusText}`);
        }
        const pdfData = await response.arrayBuffer();
        const loadingTask = getDocument({ data: pdfData });
        const pdf = await loadingTask.promise;
        setNumPages(pdf.numPages);
        setLoading(false); // Set loading to false when PDF is loaded

        // Clear existing canvases (optional, if needed)
        if (canvasContainerRef.current) {
          canvasContainerRef.current.innerHTML = '';
        }

        const screenWidth = window.innerWidth;

        // Render each page
        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
          if (renderedPages.current.has(pageNum)) continue;

          const page = await pdf.getPage(pageNum);
          // Dynamically scale based on screen width for responsiveness
          const scale = screenWidth <= 768 ? screenWidth / page.view[2] : 1.5; // Smaller scale for mobile
          const viewport = page.getViewport({ scale });

          await renderPage(page, viewport, canvasContainerRef.current);
          renderedPages.current.add(pageNum);
        }
      } catch (err) {
        console.error('Error loading PDF:', err);
        setError('Failed to load PDF. Please check the URL or try again later.');
        setLoading(false); // Stop loading if there's an error
      }
    };

    fetchPDF();
  }, [url]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'black', minHeight: '100vh' }}>
      {loading ? (
        <Spinner animation="border" variant="light" /> // Spinner shown while loading
      ) : error ? (
        <div style={{ color: 'red' }}>{error}</div> // Display error message
      ) : (
        <div ref={canvasContainerRef} style={{ width: '100%', overflow: 'auto' }} />
      )}
    </div>
  );
};

const App = () => {
  const data = localStorage.getItem('pdfurl');
  const pdfUrl = data || "https://secret-life.s3.eu-north-1.amazonaws.com/files/1725628511037_Two_Twisted_Crowns.pdf";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!pdfUrl) {
    return <div>No PDF URL found in location state.</div>;
  }

  return (
    <div style={{ position: 'relative', background: 'black' }}>
      <PDFViewer url={pdfUrl} />
    </div>
  );
};

export default App;
