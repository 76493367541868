import { IoIosArrowDown } from "react-icons/io";
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import './Header.css';

const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [topPicks, setTopPicks] = useState([]);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const fetchDataGenr = async (url, setState) => {
    try {
      const response = await axios.get(url);
      const formattedData = response.data.data.map((item) => ({
        ...item,
        id: item._id,
      }));
      setState(formattedData.reverse());
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchData = async (url, setState) => {
    try {
      const response = await axios.get(url);
      const formattedData = response.data.data.map((item) => ({
        ...item,
        id: item._id,
      }));
      setState(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchCategoryData = async (categoryId) => {
    try {
      await axios.post('https://backend.mysecretlit.com/api/user/genreClick', { categoryId });
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  };

  const handleCategoryClick = (category) => {
    fetchCategoryData(category.id);
    const encodedCategory = encodeURIComponent(JSON.stringify(category));
    navigate(`/Genres/${category.name}?datacat=${encodedCategory}`);
    setIsOpen(false);
  };

  useEffect(() => {
    fetchDataGenr('https://backend.mysecretlit.com/api/user/getGenres?type=test', setCategories);
    fetchData('https://backend.mysecretlit.com/api/user/getTopPicks', setTopPicks);
  }, []);

  const splitCategories = (index) =>
    categories.slice(index * Math.ceil(categories.length / 3), (index + 1) * Math.ceil(categories.length / 3));

  return (
       <div className="sec-center" ref={dropdownRef}> 
      <input className="dropdown" type="radio" id="dropdown" name="dropdown" checked={isOpen}   style={{display:'none'}}/>
      <label className="for-dropdown" onClick={toggleDropdown} htmlFor="dropdown">Genres<IoIosArrowDown className="uil uil-arrow-down" /></label>
      <div className="section-dropdown">
        <div className="dropdwon-left">
        {[0, 1, 2].map(index => (
  <div className="dopnwonDiv" key={index}>
    {categories.slice(index * Math.ceil(categories.length / 3), (index + 1) * Math.ceil(categories.length / 3)).map(cat => (
      <a href="#" className="allankertagDropdown" onClick={() => handleCategoryClick(cat)} key={cat.id}>{cat.name}</a>
    ))}
  </div>
))}
        </div>
        <div className="dropdwon-right">
          <div className="dopnwonDiv">
            <h6 className="heading-dropdwoncatg" style={{color:'white',fontSize:'20px'}}>Top picks</h6>
            {topPicks?.map(pick => (
              <a href="#" className="allankertagDropdown" onClick={() => handleCategoryClick(pick)} key={pick.id}>{pick.name}</a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;
