// import React, { useState } from 'react';
// import { Skeleton } from '@mui/material';
// import './BookCardTem.css';
// import { IoStar } from "react-icons/io5";
// import { Container } from 'react-bootstrap';
// import { FaRegHeart, FaHeart } from "react-icons/fa";

// const BookCardTem = ({
//   FirstTopTag,
//   SecTopTag,
//   bookcategoriesFirst,
//   bookcategoriesSec,
//   rating,
//   bookImg,
//   color,
//   loading,
//   isFavorite,
//   onHeartClick
// }) => {
//   const [isFav, setIsFav] = useState(isFavorite);
//   console.log('isFavoriteisFavorite',isFavorite)
//   const textColor = color === undefined || color === false ? 'black' : color;
//   const backgroundColor = color === false ? 'transparent' : 'transparent';
//   const skeletonColor = '#6c757d';

//   const handleHeartClick = (e) => {
//     e.stopPropagation();
//     setIsFav(!isFav);
//     onHeartClick(!isFav); // Pass the new favorite status
//   };

//   return (
//     <div className="main-card-tem" style={{ color: textColor, backgroundColor: backgroundColor }}>
//       {loading ? (
//         <Skeleton sx={{ bgcolor: skeletonColor }} variant="rectangular" width={'100%'} height={300} className='imagecardTerm' />
//       ) : (
//         <img src={bookImg || "https://media.istockphoto.com/id/1481862788/photo/stack-of-books-with-blurred-bookshelf-background-reading-learning-education-or-home-office.webp?b=1&s=170667a&w=0&k=20&c=XY_j8T8iB8XXqkW09OT_1sAZ7BtjfExi3H8XnZtp8HM="} alt="" className='imagecardTerm' />
//       )}
//       <div className="textHeading">
//         <Container>
//           {loading ? (
//             <Skeleton sx={{ bgcolor: skeletonColor, marginLeft: 1, marginBottom: 1 }} variant="text" width="92%" />
//           ) : (
//             <div className='row-main'>
//               <div className='booking-first-div-s'>
//                 <span className='first'>{bookcategoriesFirst}</span> <span className='line-slash'> /</span> <span className='Second'>{bookcategoriesSec}</span>
//               </div>
//               <div className='booking-first-div-s second-div-booking'>
//                 <div className="rating">
//                   <IoStar className='rating-star' />
//                   <p className="ratingText">{rating}</p>
//                   {isFav ? (
//                     <FaHeart className='heart-transparent' onClick={handleHeartClick} />
//                   ) : (
//                     <FaRegHeart className='heart-transparent' style={{color:'white'}} onClick={handleHeartClick} />
//                   )}
//                 </div>
//               </div>
//             </div>
//           )}
//         </Container>
//       </div>
//     </div>
//   );
// };

// BookCardTem.defaultProps = {
//   color: 'black'
// };

// export default BookCardTem;





import React, { useState, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import './BookCardTem.css';
import { IoStar } from "react-icons/io5";
import { Container } from 'react-bootstrap';
import { FaRegHeart, FaHeart } from "react-icons/fa";

const BookCardTem = ({
  FirstTopTag,
  SecTopTag,
  bookcategoriesFirst,
  bookcategoriesSec,
  rating,
  bookImg,
  color,
  loading,
  isFavorite,
  onHeartClick
}) => {
  const [isFav, setIsFav] = useState(isFavorite);

  // Synchronize the local favorite state with the prop
  useEffect(() => {
      setIsFav(isFavorite);
  }, [isFavorite]);

  const textColor = color === undefined || color === false ? 'black' : color;
  const backgroundColor = color === false ? 'transparent' : 'transparent';
  const skeletonColor = '#6c757d';

  const handleHeartClick = (e) => {
    e.stopPropagation();
    const newFavStatus = !isFav; // Store the new status
    setIsFav(newFavStatus); // Update local state
    onHeartClick(newFavStatus); // Pass the new favorite status to the parent
  };

  return (
    <div className="main-card-tem" style={{ color: textColor, backgroundColor: backgroundColor }}>
      {loading ? (
        <Skeleton sx={{ bgcolor: skeletonColor }} variant="rectangular" width={'100%'} height={300} className='imagecardTerm' />
      ) : (
        <img src={bookImg || "https://media.istockphoto.com/id/1481862788/photo/stack-of-books-with-blurred-bookshelf-background-reading-learning-education-or-home-office.webp?b=1&s=170667a&w=0&k=20&c=XY_j8T8iB8XXqkW09OT_1sAZ7BtjfExi3H8XnZtp8HM="} alt="" className='imagecardTerm' />
      )}
      <div className="textHeading">
        <Container>
          {loading ? (
            <Skeleton sx={{ bgcolor: skeletonColor, marginLeft: 1, marginBottom: 1 }} variant="text" width="92%" />
          ) : (
            <div className='row-main'>
              <div className='booking-first-div-s'>
                <span className='first'>{bookcategoriesFirst}</span> <span className='line-slash'> /</span> <span className='Second'>{bookcategoriesSec}</span>
              </div>
              <div className='booking-first-div-s second-div-booking'>
                <div className="rating">
                  <IoStar className='rating-star' />
                  <p className="ratingText">{rating}</p>
                  {isFav ? (
                    <FaHeart className='heart-transparent' style={{ color: 'red' }} onClick={handleHeartClick} />
                  ) : (
                    <FaRegHeart className='heart-transparent' style={{ color: 'white' }} onClick={handleHeartClick} />
                  )}
                </div>
              </div>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

BookCardTem.defaultProps = {
  color: 'black'
};

export default BookCardTem;
