// import React, { useState, useEffect } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
// import { Skeleton } from '@mui/material'; // Using Skeleton for the full-screen loader
// import BannerImg from '../../assets/images/Banner.png';
// import './CaroselHome.css';
// import TextBanner from './TextBanner';
// import { useNavigate } from 'react-router-dom';

// const Carosel = ({ Bannerdata }) => {
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (Bannerdata && Bannerdata.length > 0) {
//       setLoading(false);
//     }
//   }, [Bannerdata]);

//   const handleCarouselItemClick = (bookData) => {
//     navigate('/BookDetails', { state: { data: bookData } });
//   };

//   return (
//     <>
//       {loading ? (
//         <div className="loader-container">
//            <Skeleton sx={{ bgcolor: '#6c757d' }} variant="rectangular" width="100%" height="100%" />
//         </div>
//       ) : (
//         <Carousel className="carousel-container" pause="false" fade style={{background:'black'}}>
//           {Bannerdata?.map((item, index) => (
//             <Carousel.Item key={index} interval={12000} onClick={() => handleCarouselItemClick(item)}>
//               <img className="d-block w-100 carousel-img" src={BannerImg} alt="Slide" />
//               <Carousel.Caption>
//                 <TextBanner
//                   ImageBook={item?.coverImage}
//                   Heading={item?.title}
//                   RedTegHeading={item?.banner}
//                   Details={item?.shortDescription
//                   }
//                 />
//               </Carousel.Caption>
//             </Carousel.Item>
//           ))}
//         </Carousel>
//       )}
//     </>
//   );
// };

// export default Carosel;
import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Skeleton } from '@mui/material'; // Using Skeleton for the full-screen loader
import BannerImg from '../../assets/images/Banner.png'; // Adjust this path to your image
import './CaroselHome.css';
import TextBanner from './TextBanner';
import { useNavigate } from 'react-router-dom';

const Carosel = ({ Bannerdata }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (Bannerdata && Bannerdata.length > 0) {
      setLoading(false);
    }
  }, [Bannerdata]);

  const handleCarouselItemClick = (bookData) => {
    navigate('/BookDetails', { state: { data: bookData } });
  };

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <Skeleton sx={{ bgcolor: '#6c757d' }} variant="rectangular" width="100%" height="100%" />
        </div>
      ) : (
        <Carousel className="carousel-container" pause="false" fade >
          {Bannerdata?.map((item, index) => (
            <Carousel.Item key={index} interval={10000} >
              <img className="d-block w-100 carousel-img" style={{backgroundSize:'cover'}} src={BannerImg} alt="Slide" />
              <Carousel.Caption>
                <TextBanner
                BtnClickhandle={() => handleCarouselItemClick(item)}
                  ImageBook={item?.coverImage}
                  Heading={item?.title}
                  RedTegHeading={item?.banner}
                  Details={item?.shortDescription}
                />
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default Carosel;
