
import React, { useState, useEffect } from 'react';
import './Header.css';
import Form from 'react-bootstrap/Form';
import Logo from '../assets/images/logowhite.png';
import { NavLink, useNavigate } from 'react-router-dom';
import { CiSearch } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import FormHeader from '../components/form/Form';
import GoogleIcon from '../assets/images/google (1).png'
import HistoryIcon from '../assets/images/history.png'
import PurshaseHis from '../assets/images/invoice.png'
import { MdOutlineStarPurple500 } from "react-icons/md";
import LogoutIcon from '../assets/images/logout.png'
import Coupon from '../assets/images/coupon.png'
import SettingIon from '../assets/images/settings.png'
import axios from 'axios';
import GenderIcon from '../assets/images/person.png'
import defulatimg from '../assets/images/defulat image.png'

function Header({ isMenuOpen, toggleMenu, closedMenu }) {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [formType, setFormType] = useState('login');
    const [user, setUser] = useState(null);
    const [newBooks, setNewBooks] = useState(false);
    const [selectedImage, setSelectedImage] = useState(defulatimg); // Default image
    const [selectedGender, setSelectedGender] = useState('Other'); 
    const ImageProfileLocal = localStorage.getItem("profileimage")
    const checktoken = localStorage.getItem('token');
    const validImage = ImageProfileLocal && ImageProfileLocal !== "undefined" && ImageProfileLocal !== "null" && ImageProfileLocal !== "" ? ImageProfileLocal : selectedImage;

    const uploadFile = async (file, type) => {
        try {
          const formData = new FormData();
          formData.append("file", file);
      
          const response = await axios.post('https://backend.mysecretlit.com/api/uploadFile', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
      
          return response.data.data.path; // Return the file path (URL) from the response
        } catch (error) {
          console.error(`Error uploading ${type}:`, error);
          return "";
        }
      };
      const handleImageUpload = async (event) => {
        const file = event.target.files[0]; // Get the uploaded file
        
        if (file) {
          try {
          
            const imageUrl = await uploadFile(file, 'image');
            console.log('imageUrl',imageUrl)
            localStorage.setItem('profileimage',imageUrl)
            if (imageUrl) {
              await axios.patch('https://backend.mysecretlit.com/api/user/updateProfile', {
                profilePicture: imageUrl, // Send the image URL in the API request
              },{
                headers: {
                  Authorization: `Bearer ${checktoken}`,
                },
              });
      
              setSelectedImage(imageUrl);
              
            } else {
            }
          } catch (error) {
            console.error('Error updating profile with image:', error);
          }
        }
        closedMenu()
      };
    useEffect(() => {
      const loggedInUser = localStorage.getItem('user');
      if (loggedInUser) {
        const parsedUser = JSON.parse(loggedInUser);
        setSelectedGender(parsedUser?.gender || 'Other'); // Update selectedGender when user is set
      }
    }, []);
  
    useEffect(() => {
        // Check for user in local storage on component mount
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            setUser(storedUser);
        }
   
    }, []);  
    const handleGenderChange = (event) => {
        setSelectedGender(event.target.value);
      };
    const handleLogin = (userData) => {
        // Save user data to state and local storage
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        setModalShow(false);
    };

    const handleLogout = () => {
      localStorage.removeItem('user');
      localStorage.clear();
      localStorage.removeItem('profileimage')
      localStorage.removeItem('userId')
      localStorage.removeItem('token');
      localStorage.removeItem('paymentPlain');
      setUser(null);
      navigate('/');
      window.location.reload(); 
    };

    return (
        <header className="header">
            <div className='innerdiv-display'>
            <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={(e) => {
                e.stopPropagation();
                toggleMenu();
            }}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            <div className="logo"><img src={Logo} alt="Logo" style={{ height: '4vh' }} className='header-img' onClick={() => navigate('/')} /></div>
            <div className="icon-header-search"><CiSearch style={{ fontSize: '20px', color: 'white' }} onClick={()=>navigate('/search-books')} /></div>
            </div>
            <div className="down-responsiveHeader">
                    <NavLink to="/" exact activeClassName="active" className='naviagtion-a' style={{marginLeft:0}}>Home</NavLink>
                    <NavLink to="/Genremobile"  exact activeClassName="active" className='naviagtion-a'>Genres</NavLink>
                    <NavLink to="/Series" exact activeClassName="active" className='naviagtion-a'>Series</NavLink>
              <NavLink to='/AudioBooks' exact activeClassName="active" className='naviagtion-a'>AudioBooks</NavLink>
              <NavLink to='/New' exact activeClassName="active" className='naviagtion-a' style={{position:'relative'}}>New {newBooks && (
                  <div style={{position: 'absolute',right:'-2px',top: '-5px'}}>
                    <MdOutlineStarPurple500 style={{ color: '#EE2D2F',  fontSize: '11px', }} />
                  </div>
                )}</NavLink>
                    </div>
            <nav className={`menu ${isMenuOpen ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
                <div style={{ background: 'black', }}>
                    <div className='iconWithLogoDiv'>
                        <div className="logo" onClick={closedMenu}><img src={Logo} alt="Logo" className='header-img' onClick={() => navigate('/')} /></div>
                        <IoMdClose style={{ fontSize: '28px', color: 'white' }} onClick={closedMenu} />
                    </div>
                    
                    <div className="button-header-responsive">
                        {user ? (
                            <>
                            <div className='btn-header-responsive-div-google'>
                            <span style={{paddingRight:'10px'}}>
                                
                                {/* <img src={GoogleIcon} alt="" style={{ borderRadius:' 50px',width: '30px',height: '30px',objectFit: 'contain',background: 'white',padding: '6px'}} /> */
                                }
                                <div style={{ position: 'relative' }}>
    <img
            // src={ImageProfileLocal || defulatimg}
            src={validImage}
            alt="profile"
            style={{
              boxShadow:'0px 0px 2px 1px red',
              height: '40px',
              width: '40px',
              borderRadius: '50%',
              objectFit: 'cover',
              cursor: 'pointer',
              // padding:'10px',
          
              transition: 'transform 0.3s ease-in-out'
            }}
            onClick={() => document.getElementById('imageUpload').click()} // Trigger file input on click
          />
          <input
            type="file"
            id="imageUpload"
            accept="image/*"
            style={{ display: 'none' }} 
            onChange={handleImageUpload} 
          />
    </div>
                                </span>
                                <span className='user-email'>{user.email}</span>
                                </div>
                              
                            </>
                        ) : (
                          <>
                                                           <button className='btn-responsive-btn' onClick={() => { 
                                                              setFormType('login'); 
                                                              setModalShow(true);
                                                          }}>Sign in</button>
                                                          <button className='btn-responsive-btn' onClick={() => { 
                                                              setFormType('signup'); 
                                                              setModalShow(true);
                                                          }}>Sign up</button>
                                                      </>
                        )}
                    </div>
                </div>
                <ul>
                    {user ? (
                        <>
                        <NavLink to='' onClick={closedMenu} style={{textDecoration:'none',background:'none',}} className='paragraph-text-menu'> 
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <p className='paragraph-text-menu' style={{paddingBottom:'0px',paddingTop:'20px'}}>Gender </p> 
                        <div className="colm" style={{ display: 'flex', justifyContent: 'end',paddingTop:'20px',paddingRight:'10px',width:'70%',marginRight:10}}>
                          <Form.Select
            style={{
              width: '45%',
              fontSize: '12px',
              background: 'black',
              color: 'white',
              textAlign: 'center',
              padding: '0px',
              cursor:'pointer',
              paddingLeft:'14px'
            
            }}
            aria-label="Default select example"
            value={selectedGender} 
            onChange={handleGenderChange} 
          >
            <option value="Other">Other</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Form.Select>
                        </div>
                        </div>
                       
                        </NavLink>
                             <NavLink to='mypage/RecentRead' onClick={closedMenu} style={{textDecoration:'none',background:'none',}} className='paragraph-text-menu'> <p className='paragraph-text-menu' style={{paddingTop:'15px'}}>Recently Read </p> </NavLink>
                             <NavLink to='mypage/is-favorite-books' onClick={closedMenu} style={{textDecoration:'none',background:'none' }} className='paragraph-text-menu'> <p className='paragraph-text-menu' style={{paddingTop:'15px'}}>Favorite </p></NavLink>
                             <NavLink to='mypage/promotion_code'onClick={closedMenu} style={{textDecoration:'none',background:'none' }} className='paragraph-text-menu'>   <p className='paragraph-text-menu' style={{paddingTop:'15px'}}  >Promo Code</p> </NavLink> 
                             <NavLink to='mypage/accountSetting' onClick={closedMenu} style={{textDecoration:'none',background:'none' }} className='paragraph-text-menu'> <p className='paragraph-text-menu'  style={{paddingTop:'15px'}}  >Account Settings </p></NavLink>
                             <NavLink to='mypage/customer-services' onClick={closedMenu} style={{textDecoration:'none',background:'none' }} className='paragraph-text-menu'> <p className='paragraph-text-menu'  style={{paddingTop:'15px'}}>Customer Services </p></NavLink>
                             <NavLink to='' onClick={handleLogout} style={{textDecoration:'none',background:'none' }} className='paragraph-text-menu'> <p className='paragraph-text-menu'  style={{paddingTop:'15px',paddingBottom:20}} >Logout </p></NavLink>
                        
                        </>
                    ) : (
                        <li><a href="mypage/customer-services">Customer Service</a></li>
                    )}
                </ul>
            </nav>
                       <FormHeader
  show={modalShow}
  onHide={() => setModalShow(false)}
  type={formType}  // Pass form type (either 'signin' or 'signup')
  key={formType}  // Add key here to force re-render when formType changes
/>        </header>
    );
}

export default Header;
// import React, { useState, useEffect } from 'react';
// import './Header.css';
// import Form from 'react-bootstrap/Form';
// import Logo from '../assets/images/logowhite.png';
// import { NavLink, useNavigate } from 'react-router-dom';
// import { CiSearch } from "react-icons/ci";
// import { IoMdClose } from "react-icons/io";
// import FormHeader from '../components/form/Form'; // Assuming FormHeader can handle sign in and sign up
// import GoogleIcon from '../assets/images/google (1).png'
// import HistoryIcon from '../assets/images/history.png'
// import PurshaseHis from '../assets/images/invoice.png'
// import { MdOutlineStarPurple500 } from "react-icons/md";
// import LogoutIcon from '../assets/images/logout.png'
// import Coupon from '../assets/images/coupon.png'
// import SettingIon from '../assets/images/settings.png'
// import axios from 'axios';
// import GenderIcon from '../assets/images/person.png'
// import defulatimg from '../assets/images/apple-touch-icon.png'

// function Header({ isMenuOpen, toggleMenu, closedMenu }) {
//     const navigate = useNavigate();
//     const [modalShow, setModalShow] = useState(false);
//     const [formType, setFormType] = useState('login'); // Add form type state (either 'signin' or 'signup')

//     const [user, setUser] = useState(null);
//     const [newBooks, setNewBooks] = useState(false);
//     const [selectedGender, setSelectedGender] = useState('Other'); 
//     const ImageProfileLocal = localStorage.getItem("profileimage")
//     const checktoken = localStorage.getItem('token');

//     const uploadFile = async (file, type) => {
//         try {
//           const formData = new FormData();
//           formData.append("file", file);
      
//           const response = await axios.post('https://backend.mysecretlit.com/api/uploadFile', formData, {
//             headers: {
//               'Content-Type': 'multipart/form-data',
//             },
//           });
//           return response.data.data.path; 
//         } catch (error) {
//           console.error(`Error uploading ${type}:`, error);
//           return "";
//         }
//       };

//     const handleImageUpload = async (event) => {
//         const file = event.target.files[0];
//         if (file) {
//           try {
//             const imageUrl = await uploadFile(file, 'image');
//             localStorage.setItem('profileimage', imageUrl)
//             if (imageUrl) {
//               await axios.patch('https://backend.mysecretlit.com/api/user/updateProfile', {
//                 profilePicture: imageUrl,
//               },{
//                 headers: {
//                   Authorization: `Bearer ${checktoken}`,
//                 },
//               });
//             }
//           } catch (error) {
//             console.error('Error updating profile with image:', error);
//           }
//         }
//         closedMenu();
//       };

//     useEffect(() => {
//       const loggedInUser = localStorage.getItem('user');
//       if (loggedInUser) {
//         const parsedUser = JSON.parse(loggedInUser);
//         setSelectedGender(parsedUser?.gender || 'Other'); 
//       }
//     }, []);
  
//     useEffect(() => {
//         const storedUser = JSON.parse(localStorage.getItem('user'));
//         if (storedUser) {
//             setUser(storedUser);
//         }
//     }, []);  

//     const handleGenderChange = (event) => {
//         setSelectedGender(event.target.value);
//       };

//     const handleLogin = (userData) => {
//         setUser(userData);
//         localStorage.setItem('user', JSON.stringify(userData));
//         setModalShow(false);
//     };

//     const handleLogout = () => {
//         setUser(null);
//         localStorage.removeItem('user');
//         localStorage.removeItem('paymentPlain');
//         closedMenu();
//     };

//     return (
//         <header className="header">
//             <div className='innerdiv-display'>
//             <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={(e) => {
//                 e.stopPropagation();
//                 toggleMenu();
//             }}>
//                 <div className="line"></div>
//                 <div className="line"></div>
//                 <div className="line"></div>
//             </div>
//             <div className="logo"><img src={Logo} alt="Logo" style={{ height: '4vh' }} className='header-img' onClick={() => navigate('/')} /></div>
//             <div className="icon-header-search"><CiSearch style={{ fontSize: '20px', color: 'white' }} onClick={()=>navigate('/search-books')} /></div>
//             </div>
//             <div className="down-responsiveHeader">
//                     <NavLink to="/" exact activeClassName="active" className='naviagtion-a' style={{marginLeft:0}}>Home</NavLink>
//                     <NavLink to="/Genremobile"  exact activeClassName="active" className='naviagtion-a'>Genres</NavLink>
//                     <NavLink to="/Series" exact activeClassName="active" className='naviagtion-a'>Series</NavLink>
//                     <NavLink to='/AudioBooks' exact activeClassName="active" className='naviagtion-a'>AudioBooks</NavLink>
//                     <NavLink to='/New' exact activeClassName="active" className='naviagtion-a' style={{position:'relative'}}>New {newBooks && (
//                   <div style={{position: 'absolute',right:'-2px',top: '-5px'}}>
//                     <MdOutlineStarPurple500 style={{ color: '#EE2D2F',  fontSize: '11px', }} />
//                   </div>
//                 )}</NavLink>
//             </div>
//             <nav className={`menu ${isMenuOpen ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
//                 <div style={{ background: 'rgba(238, 45, 47, 1)', padding: '10px 17px', paddingBottom: '20px' }}>
//                     <div className='iconWithLogoDiv'>
//                         <div className="logo" onClick={closedMenu}><img src={Logo} alt="Logo" className='header-img' onClick={() => navigate('/')} /></div>
//                         <IoMdClose style={{ fontSize: '28px', color: 'white' }} onClick={closedMenu} />
//                     </div>
                    
//                     <div className="button-header-responsive">
//                         {user ? (
//                             <>
//                             <div className='btn-header-responsive-div-google'>
//                                 <span style={{paddingRight:'10px'}}>
//                                 <div style={{ position: 'relative' }}>
//                                     <img
//                                         src={ImageProfileLocal || defulatimg}
//                                         alt="profile"
//                                         style={{
//                                           boxShadow:'0px 0px 2px 1px red',
//                                           height: '40px',
//                                           width: '40px',
//                                           borderRadius: '50%',
//                                           objectFit: 'cover',
//                                           cursor: 'pointer',
//                                           transition: 'transform 0.3s ease-in-out'
//                                         }}
//                                         onClick={() => document.getElementById('imageUpload').click()}
//                                     />
//                                     <input
//                                         type="file"
//                                         id="imageUpload"
//                                         accept="image/*"
//                                         style={{ display: 'none' }} 
//                                         onChange={handleImageUpload} 
//                                     />
//                                 </div>
//                                 </span>
//                                 <span className='user-email'>{user.email}</span>
//                             </div>
//                             </>
//                         ) : (
//                             <>
//                                 <button className='btn-responsive-btn' onClick={() => { 
//                                     setFormType('login'); 
//                                     setModalShow(true);
//                                 }}>Sign in</button>
//                                 <button className='btn-responsive-btn' onClick={() => { 
//                                     setFormType('signup'); 
//                                     setModalShow(true);
//                                 }}>Sign up</button>
//                             </>
//                         )}
//                     </div>
//                 </div>
//                 <ul>
//                     {user ? (
//                         <>
//                             <NavLink to='' onClick={closedMenu} className='paragraph-text-menu'> 
//                                 <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
//                                     <p className='paragraph-text-menu'> <img src={GenderIcon} alt="" style={{width:'20px',height:'20px',marginRight:5}} srcset="" /> Gender </p> 
//                                     <Form.Select
//                                         style={{
//                                           width: '58%',
//                                           fontSize: '12px',
//                                           background: 'black',
//                                           color: 'white',
//                                           textAlign: 'center',
//                                           padding: '0px',
//                                           cursor:'pointer'
//                                         }}
//                                         aria-label="Default select example"
//                                         value={selectedGender} 
//                                         onChange={handleGenderChange}
//                                     >
//                                         <option value="Other">Other</option>
//                                         <option value="Male">Male</option>
//                                         <option value="Female">Female</option>
//                                     </Form.Select>
//                                 </div>
//                             </NavLink>
//                             <NavLink to='/purchase-history' onClick={closedMenu} className='paragraph-text-menu'> <img src={PurshaseHis} alt="" style={{width:'20px',height:'20px',marginRight:5}} srcset="" /> Purchase history</NavLink>
//                             <NavLink to='/savedBooks' onClick={closedMenu} className='paragraph-text-menu'> <MdOutlineStarPurple500 style={{fontSize:'22px',paddingRight:5}} /> My Collection</NavLink>
//                             <NavLink to='/coupons' onClick={closedMenu} className='paragraph-text-menu'> <img src={Coupon} alt="" style={{width:'20px',height:'20px',marginRight:5}} srcset="" /> Coupons</NavLink>
//                             <NavLink to='/orderHistory' onClick={closedMenu} className='paragraph-text-menu'> <img src={HistoryIcon} alt="" style={{width:'20px',height:'20px',marginRight:5}} srcset="" /> History</NavLink>
//                             <NavLink to='/settings' onClick={closedMenu} className='paragraph-text-menu'> <img src={SettingIon} alt="" style={{width:'20px',height:'20px',marginRight:5}} srcset="" /> Settings</NavLink>
//                             <NavLink to='' onClick={handleLogout} className='paragraph-text-menu'> <img src={LogoutIcon} alt="" style={{width:'20px',height:'20px',marginRight:5}} srcset="" /> Logout</NavLink>
//                         </>
//                     ) : (
//                         <>
//                         <li><a href="mypage/customer-services">Customer Service</a></li>
//                         </>
//                     )}
//                 </ul>
//             </nav>
//             <FormHeader
//   show={modalShow}
//   onHide={() => setModalShow(false)}
//   type={formType}  // Pass form type (either 'signin' or 'signup')
//   key={formType}  // Add key here to force re-render when formType changes
// />
//         </header>
//     );
// }

// export default Header;
