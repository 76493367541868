import React, { useState, useEffect } from 'react'
import BookCardTem from '../components/BookCardtem/BookCardTem'
import './all.css'
import { Pagination } from '@mui/material'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'


const NewPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 18;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const naviagte = useNavigate();
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    window.scrollTo(0, 0);
    const now = new Date().toISOString();
    localStorage.setItem('lastVisitNewPage', now);
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Check if userId exists before making the API call
      const url = userId 
        ? `https://backend.mysecretlit.com/api/book/getBooks?type=book&userId=${userId}` 
        : 'https://backend.mysecretlit.com/api/book/getBooks?type=book';

      // Make the API call
      const response = await axios.get(url);

      // Set the data, reverse, and slice the first 60 items
      setData(response.data?.data?.reverse().slice(0, 60) || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false); // Stop loading indicator
    }
};


  useEffect(() => {
    fetchData();
  }, []);
  const HeartFunction = async (bookId, isFav) => {
    const checktoken = localStorage.getItem('token');
    try {
        if (bookId) {
            let updatedData;
            if (!isFav) {
                const res = await axios.delete(`https://backend.mysecretlit.com/api/user/deletefavBook?bookId=${bookId}`, {
                    headers: {
                        Authorization: `Bearer ${checktoken}`,
                    },
                });
                if (res.status === 200) {
                    console.log('Book removed from favorites successfully');
                    updatedData = data.map((item) => 
                        item.id === bookId ? { ...item, isFav: false } : item
                    );
                    setData(updatedData);
                }
            } else {
                const res = await axios.post('https://backend.mysecretlit.com/api/user/favBook', {
                    bookId: bookId
                }, {
                    headers: {
                        Authorization: `Bearer ${checktoken}`,
                    },
                });
                if (res.status === 200) {
                    console.log('Book added to favorites successfully');
                    updatedData = data.map((item) => 
                        item.id === bookId ? { ...item, isFav: true } : item
                    );
                    setData(updatedData);
                }
            }
        }
    } catch (error) {
        console.error('Error making API call:', error);
    }
};
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };
  // const HeartFuntion = async(item)=>{
  //   const checktoken = localStorage.getItem('token');
  //   try {
  //       if (item) {
  //           const res = await axios.post('https://backend.mysecretlit.com/api/user/favBook',{bookId:item}, {
  //               headers: {
  //                   Authorization: `Bearer ${checktoken}`,
  //               },
  //           });
  //           if (res.status === 200) {
               
  //           } else {
  //               console.log('Failed to delete book. Please try again.');
  //           }
  //       }
  //   } catch (error) {
  //       console.error('Error making API call:', error);
  //   }
  //   }
  return (
    <div style={{ width: '100%', background: 'black' }}>
      <div className='main-series-div-width'>
        <h2 className='h2-main-heading'>Newly Added</h2>
        <div className='seriesdiv-row'>
             {(isLoading ? Array.from(new Array(18)) : currentItems)?.map((card, index) => (
            <div key={index} style={{cursor:'pointer'}} className='custom-deatils-card'
            //  onClick={ () => naviagte('/BookDetails', { state: { data: card } })}
            onClick={() => {
              if (card.series) {
                naviagte('/book-details/you-may-also-like', { state: { data: card,Bookrender:card._id } });
              } else {
                naviagte('/BookDetails', { state: { data: card } });
              }
          }}
             >
            <BookCardTem
                                isFavorite={card?.isFav} // Pass the current favorite status
                                onHeartClick={(newFavStatus) => HeartFunction(card?._id, newFavStatus)} // Pass the book ID and new favorite status
                                Bookname={card?.title}
                                firstTopTag={card?.FirstTopTag}
                                secTopTag={card?.SecTopTag}
                                bookcategoriesFirst={card?.category?.name}
                                bookcategoriesSec={card?.secondaryCategory?.name}
                                rating={card?.rating}
                                bookImg={card?.coverImage}
                                loading={isLoading}
                            />
          </div>
        ))}
        </div>
        <Pagination
          count={Math.ceil(data.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
        />
      </div>
    </div>
  )
}

export default NewPage
