// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import bookReducer from './slices/booksReducer';
import Categories from './slices/Categories';

const store = configureStore({
  reducer: {
    book: bookReducer,
    categories: Categories,
  },
});

export default store;
