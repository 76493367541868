
import React, { useEffect, useState } from 'react';
import './Account.css';
import { IoMdInformationCircleOutline } from "react-icons/io";
import BookCardTem from '../../components/BookCardtem/BookCardTem';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Spinner } from 'react-bootstrap'; 

const IsFavBooks = () => {
    const [readbook, setRecentBooks] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigation = useNavigate();
    const checktoken = localStorage.getItem('token');
    useEffect(() => {
        const fetchRecentRead = async () => {
            setLoading(true);
           
                try {
                    const response = await axios.get('https://backend.mysecretlit.com/api/user/getfavBooks', {
                        headers: {
                            Authorization: `Bearer ${checktoken}`,
                          },
                    });
                    setRecentBooks(response?.data?.data?.favoriteBooks.reverse());
                } catch (error) {
                    console.error('Error fetching recently read books:', error.response);
                } finally {
                    setLoading(false);
                }
            }

        fetchRecentRead();
    }, []);

    const handleHeartClick = async (bookId) => {
        try {
            await axios.delete(`https://backend.mysecretlit.com/api/user/deletefavBook?bookId=${bookId}`, {
                headers: {
                    Authorization: `Bearer ${checktoken}`,
                },
            });
            
            setRecentBooks(prevBooks => prevBooks.filter(book => book._id !== bookId));
        } catch (error) {
            console.error('Error removing book from favorites:', error.response || error.message);
        }
    };

    const handleBookClick = (bookData) => {
        navigation('/BookDetails', { state: { data: bookData } });
    };

    return (
            <div className='recentRead-main' id='recent-read-fav-books'  style={{ 
                display: 'block', 
                height: readbook.length === 0 ? '100vh' : (readbook.length >= 6 ? 'auto' : '100%') // Set height based on readbook length
            }}
        >
            <div className="rowdiv-recent additonRowDIn" style={{ background: 'black' }}>
                <div className="iconDiv">
                    <h1 className="recenredRed-heading" style={{ background: 'black', fontFamily: '"Noto Sans SC", sans-serif', paddingLeft: 0 }}>
                        Favourites
                    </h1>
                </div>
                {loading ? (
                    <div className="spinner-container">
                        <Spinner animation="border" variant="danger" /> {/* Adjust spinner style as needed */}
                    </div>
                ) : readbook.length === 0 ? (
                    <p className='paragrah-no-recenrtbook'>
                        <IoMdInformationCircleOutline style={{ fontSize: '20px', marginRight: '4px' }} />
                        There are no Favourites.
                    </p>
                ) : (
                    <div className="slider-container-rednring">
                        {(loading ? Array.from(new Array(5)) : readbook)?.map((card, index) => (
                            <div key={index} onClick={() => handleBookClick(card)} style={{ cursor: 'pointer' }} className='div-main-recentrendeing'>
                                <BookCardTem
                                    loading={loading}
                                    // additionalFunction={() => handleHeartClick(card._id)}
                                    isFavorite={true}
                                    bookId={card._id}
                                    Bookname={card?.Title}
                                    firstTopTag={card?.category?.name}
                                    secTopTag={card?.secondaryCategory?.name}
                                    bookcategoriesFirst={card?.category?.name}
                                    bookcategoriesSec={card?.secondaryCategory?.name}
                                    rating={card?.rating}
                                    bookImg={card?.coverImage }
                                    onHeartClick={() => handleHeartClick(card._id)}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default IsFavBooks;
