import React, { useState, useEffect } from 'react';
import './Form.css';
import Modal from 'react-bootstrap/Modal';
import { RxCross1 } from "react-icons/rx";
import axios from 'axios';
import { ImEye ,ImEyeBlocked} from "react-icons/im";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Google from '../../assets/images/google.png';
import Facebook from '../../assets/images/facebook.png';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { Spinner } from 'react-bootstrap';
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';

const Form = (props) => {

  const { type } = props; // Receive type prop
    const [activeForm, setActiveForm] = useState(type || 'login');
  const [showRepassword, setShowRepassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toggleRepasswordVisibility = () => setShowRepassword(!showRepassword);
  const currentLocation = localStorage.getItem("currentlocation")
  const Ipaddress = localStorage.getItem('ipaddress')
  // const [latitudeLoc, longitudeLoc] = currentLocation?.split(',').map(coord => parseFloat(coord));
  const handleClose = () => {
    setActiveForm(type); // Reset the activeForm state to an empty value or desired default
    props.onHide(); // Call the onHide prop function if it's intended to hide a modal or perform another action
  };
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    gender: '',
    latitude: currentLocation || null,
    longitude: Ipaddress || null,
  });
  const [errors, setErrors] = useState({});
  const [locationConsent, setLocationConsent] = useState(false); // State for location consent
  const navigate = useNavigate();

  // const handleTabClick = (tab) => {
  //   setActiveForm(tab);
  //   setErrors({});
  //   setUserData({
  //     email: '',
  //     password: '',})
  // };
  const handleTabClick = (tab) => {
    setActiveForm(tab);  // Update the form type
    setErrors({});  // Reset errors if needed
    setUserData({
        email: '',
        password: '',  // Reset user data
    });
    // setFormType(tab);  // Ensure formType reflects the updated state
};


  const EMAIL_VALIDATION_API_KEY = 'ba87fdb19be94920b7d33e1e2fd5cf40';

const validateEmail = async (email) => {
  try {
    const response = await axios.get(
      `https://emailvalidation.abstractapi.com/v1/?api_key=${EMAIL_VALIDATION_API_KEY}&email=${email}`
    );
    const { is_valid_format, deliverability } = response.data;

    if (is_valid_format.value && deliverability === 'DELIVERABLE') {
      return true; // Return true for valid email
      setIsLoading(false)
    } else if (deliverability === 'UNDELIVERABLE') {
      return false; // Return false for invalid email
      setIsLoading(false)
    } else {
      return false; // Return false if email validity cannot be determined
    }
  } catch (error) {
    setIsLoading(false)
    console.log(error); // Log the error for debugging
    return false; // Return false in case of any error
  } 
};

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setUserData({
  //     ...userData,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value
    });

    // Validate gender selection
    if (name === "gender" && value === "") {
      setErrors({
        ...errors,
        gender: "Gender is required."
      });
    } else {
      setErrors({
        ...errors,
        gender: ""
      });
    }
  };

  const validatePassword = (password) => {
    const errors = {};
    if (password.length <= 5) {
      errors.password = 'Password must be at least 5 characters long.';
    }
    return errors;
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserData((prevState) => ({
            ...prevState,
            latitude,
            longitude,
          }));
        },
        (error) => {
          toast.error('Unable to retrieve location. Please try again.');
          console.error('Geolocation error:', error);
        }
      );
    } else {
      toast.error('Geolocation is not supported by your browser.');
    }
  };

  useEffect(() => {
    if (activeForm === 'signup' && locationConsent) {
      getLocation();
    }
  }, [activeForm, locationConsent]);

 
  const handleFormSubmit = async (e) => {
    setIsLoading(true); 
    e.preventDefault();
    const { email, password, first_name, last_name, gender, latitude, longitude } = userData;
  
    const newErrors = {};
    if (!email) {
      newErrors.email = 'Email is required.';
    }
  
    if (!password) newErrors.password = 'Password is required.';
    else if (activeForm === 'signup') Object.assign(newErrors, validatePassword(password));
    if (activeForm === 'signup' && !first_name) newErrors.first_name = 'First Name is required.';
    if (activeForm === 'signup' && !last_name) newErrors.last_name = 'Last Name is required.';
    if (activeForm === 'signup' && !gender) newErrors.gender = 'Gender is required.';
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    try {
      if (activeForm === 'login') {
        const response = await axios.post('https://backend.mysecretlit.com/api/user/login', { email, password });
        const { user, accessToken } = response.data.data;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', accessToken);
        localStorage.setItem('profileimage', user?.profilePicture);
        localStorage.setItem('userId', user?.id);
        toast.success('Login successful');
        window.location.reload();
        navigate('/');
      } else if (activeForm === 'signup') {
        // Validate email only for signup
        const isEmailValid = await validateEmail(email);
        if (!isEmailValid) {
          setErrors({ email: 'Invalid email address. Please provide a valid email.' });
          return;
        }
  
        // If email is valid, proceed with signup
        const payload = {
          firstName: first_name,
          lastName: last_name,
          profilePicture: Ipaddress || '',
          email,
          password,
          gender,
          lat: latitude || null,
          long: longitude || null,
        };
  
        const response = await axios.post('https://backend.mysecretlit.com/api/user/signup', payload);
        const { user, accessToken } = response.data.data;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', accessToken);
        localStorage.setItem('profileimage', user?.profilePicture);
        localStorage.setItem('userId', user?.id);
        toast.success('Signup successful');
        window.location.reload();
        navigate('/');
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
        setIsLoading(false)
      } else if (error.request) {
        console.log(error.request);
        toast.error('Server not responding');
        setIsLoading(false)
      } else {
        console.log('Error', error.message);
        toast.error('Unexpected error occurred');
        setIsLoading(false)
      }
    }
  };
  const [user, setUser] = useState(null);
    const [profile, setProfile] = useState(null);

    // Google Login handler
    const logingoogle = useGoogleLogin({
      onSuccess: (codeResponse) => {
          setUser(codeResponse);  // Save the login response (token, etc.)
          
          // Fetch user profile data using the access token
          axios
            .get('https://www.googleapis.com/oauth2/v3/userinfo', {
              headers: {
                Authorization: `Bearer ${codeResponse.access_token}`,
              },
            })
            .then((res) => {
              const profileData = res.data;

              // Structure the payload based on the provided structure
              const payload = {
                  googleUser: {
                      id: profileData.sub,
                      name: profileData.name,
                      photo: profileData.picture
                  },
                  identifier: profileData.email,
                  // password: "",  // You can leave this blank if not required
                  type: "google"
              };

              // Set the profile data in the state
              setProfile(profileData);

              // Make API call to your server with the structured payload
              axios.post('https://backend.mysecretlit.com/api/user/login', payload)
                  .then((response) => {
                    const { user, accessToken } = response.data.data;
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('token', accessToken);
                    localStorage.setItem('email',profileData.email)
                    localStorage.setItem('userId', user?.id)
                    localStorage.setItem('profileimage', user?.profilePicture || profileData?.picture)
                    toast.success('login successful');
                    console.log('responseresponse',response.data.data)
                    window.location.reload();
                    
        navigate('/');
                      // Handle response (e.g., save user info in state, navigate, etc.)
                  })
                  .catch((err) => console.log('Error sending data to API:', err));
            })
            .catch((err) => console.log('Error fetching user info:', err));
      },
      onError: (error) => console.log('Login Failed:', error),
  });
    console.log("User Profile:", profile);

const handleLoginError = (error) => {
    console.error("Login Failed: ", error);
};
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
       
        <Modal.Body  >
          <div className='container' >
            
            <div className='btn-top-button-div'>
              <button
                style={{ color: 'black' }}
                id='button-top-btn'
                className={activeForm === 'login' ? 'active' : ''}
                onClick={() => handleTabClick('login')}
              >
                Sign In
              </button>
              <button
                style={{ color: 'black' }}
                id='button-top-btn'
                className={activeForm === 'signup' ? 'active' : ''}
                onClick={() => handleTabClick('signup')}
              >
                Sign Up
              </button>
            </div>
            {activeForm === 'login' && (
              <form id='formFm' onSubmit={handleFormSubmit} className='active'>
                 <div>
          <RxCross1 className='icon-btn-cross' onClick={handleClose} />
        </div>
                <div>
                  <input
                    onChange={handleInputChange}
                    className='input-class'
                    type="email"
                    id="loginEmail"
                    name="email"
                    required
                    placeholder='Email'
                    style={{color:'black'}}
                    value={userData.email}
                  />
                  {errors.email && <p className="error-message">{errors.email}</p>}
                </div>
                <div style={{position:'relative'}}>
                  <input
                    onChange={handleInputChange}
                    className='input-class'
                    type={showRepassword ? 'text' : 'password'}
                    id="loginPassword"
                    name="password"
                    required
                    placeholder='Password'
                    style={{color:'black'}}
                    value={userData.password}
                  />
                   <span style={{color:'black',position:'absolute',right: '15px',top:15}} onClick={toggleRepasswordVisibility}>
                        {showRepassword ? <ImEye style={{color:'black',}} /> : <ImEyeBlocked style={{color:'black'}} />}
                    </span>
                  {errors.password && <p className="error-message">{errors.password}</p>}
                </div>
                <div className='div-forget' style={{justifyContent:'end'}}>
                  <a href="" className="forgetpass"> Forget Password?</a>
                </div>
                <button className='btn-subit' type="submit" id='btn-marginset'> 
               
                {isLoading ? (        <>
          <Spinner animation="border" size="sm" style={{ marginRight: 5 }} /> {/* React-Bootstrap Spinner */}
     
        </>

  ) : (
    'Sign In'
  )}
                </button>
              </form>
            )}
            {activeForm === 'signup' && (
              <form id='formFm' onSubmit={handleFormSubmit} className='active'>
                 <div>
          <RxCross1 className='icon-btn-cross' onClick={handleClose} />
        </div>
                <div>
                  <input
                    onChange={handleInputChange}
                    placeholder='First Name'
                    className='input-class'
                    type="text"
                    id="firstName"
                    name="first_name"
                    required
                    style={{color:'black'}}
                    value={userData.first_name}
                  />
                  {errors.first_name && <p className="error-message">{errors.first_name}</p>}
                </div>
                <div>
                  <input
                    onChange={handleInputChange}
                    placeholder='Last Name'
                    className='input-class'
                    type="text"
                    id="lastName"
                    name="last_name"
                    required
                    value={userData.last_name}
                    style={{color:'black'}}
                  />
                  {errors.last_name && <p className="error-message">{errors.last_name}</p>}
                </div>
                <div>
                  <input
                    onChange={handleInputChange}
                    placeholder='Email'
                    className='input-class'
                    type="email"
                    id="signupEmail"
                    name="email"
                    required
                    value={userData.email}
                    style={{color:'black'}}
                  />
                  {errors.email && <p className="error-message" style={{paddingLeft:0}}>{errors.email}</p>}
                </div>
             
                <div>
      <FormControl fullWidth variant="standard" error={Boolean(errors.gender)} required>
        {/* <Select
          labelId="gender-label"
          id="gender"
          name="gender"
          value={userData.gender}
          onChange={handleInputChange}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <span style={{ color: '#ADADAD' }}>Gender</span>;
            }
            return selected; 
          }}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: 'white', 
              },
            },
          }}
        > */}
        <Select
  disableTouchRipple
  labelId="gender-label"
  id="gender"
  name="gender"
  value={userData.gender}
  onChange={handleInputChange}
  displayEmpty
  renderValue={(selected) => {
    if (!selected) {
      return <span style={{ color: '#ADADAD' }}>Gender</span>;
    }
    return selected;
  }}
  MenuProps={{
    PaperProps: {
      style: {
        backgroundColor: 'white',
      },
    },
  }}
>
          <MenuItem value="">
            <em style={{ color: 'black' }}>Select Gender</em> 
          </MenuItem>
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Select>
        {errors.gender && <FormHelperText>{errors.gender}</FormHelperText>}
      </FormControl>
    </div>
                <div style={{position:'relative'}}>
                  <input
                    onChange={handleInputChange}
                    className='input-class'
                    type={showRepassword ? 'text' : 'password'}
                    id="signupPassword"
                    name="password"
                    required
                    placeholder='Password'
                    value={userData.password}
                    style={{color:'black'}}
                  />
                   <span style={{color:'black',position:'absolute',right: '15px',top:15}} onClick={toggleRepasswordVisibility}>
                        {showRepassword ? <ImEye style={{color:'black',}} /> : <ImEyeBlocked style={{color:'black'}} />}
                    </span>
                  {errors.password && <p className="error-message">{errors.password}</p>}
                </div>
                <div className='radio-group'>
                 
                  <label htmlFor="locationConsent" style={{color:'black',fontSize:12,fontWeight:500,width:'100%'}}>By signing up, you agree to our  <br></br> <Link to='/privacy-policy' style={{color:'#EE2D2F',textDecoration:'none'}}> Terms of Service </Link> and <Link to='/privacy-policy' style={{color:'#EE2D2F',textDecoration:'none'}}> Privacy Policy </Link></label>
                </div>
                {errors.locationConsent && <p className="error-message">{errors.locationConsent}</p>}
                <button className='btn-subit' type="submit" style={{marginTop:11}}>
                {isLoading ? (        <>
          <Spinner animation="border" size="sm" style={{ marginRight: 5 }} /> {/* React-Bootstrap Spinner */}
        </>

  ) : (
    ' Sign Up'
  )}
                  
                   </button>
              </form>
            )}
            <div className="social-media">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
            <div class="line left-line"></div>
            <p className="socialmedialine">Sign In With Social Media</p>
            <div class="line right-line"></div>
          </div>
          <div className="icons">
          
          <img src={Google} alt="" style={{cursor:'pointer'}} className='icon-imgae' onClick={logingoogle} />
            <img src={Facebook} alt="" className='icon-imgae' />
          </div>
          </div>
          </div>
          
        </Modal.Body>
      </Modal>
      {/* <ToastContainer autoClose={2000} /> */}
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnHover={false}
pauseOnFocusLoss
draggable
theme="dark"
/>
    </>
  );
};

export default Form;
