
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';  // For the loading spinner
import './all.css'
import BookCardTem from '../components/BookCardtem/BookCardTem';

function SearchResultsPage() {
  const location = useLocation();
  const { query } = location.state || ''; // Extract search query from location
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [topRatedBooks, setTopRatedBooks] = useState([]);

  const fetchBooksByRecommend = async () => {
    const checktoken = localStorage.getItem('token');
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/book/getTopClickedBooks?limit=18', {
        headers: {
          Authorization: `Bearer ${checktoken}`,
        },
      });
      const data = response.data?.data || [];
      setTopRatedBooks(data);
    } catch (error) {
      console.error('Error fetching top-rated books:', error);
    }
  };

  useEffect(() => {
    fetchBooksByRecommend();
    const fetchBooks = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://backend.mysecretlit.com/api/book/getBooks?type=book');
        const books = response.data?.data || [];

        // Filter books based on the search query (title or author)
        const filteredBooks = books.filter(book =>
          book.authorName.toLowerCase().includes(query.toLowerCase()) ||
          book.title.toLowerCase().includes(query.toLowerCase())
        );

        setSearchResults(filteredBooks);
      } catch (error) {
        console.error('Error fetching search results:', error);
      } finally {
        setLoading(false);
      }
    };

    if (query) {
      fetchBooks(); // Fetch books only if there's a search query
    }
  }, [query]);

  const handleBookClick = (bookData) => {
    // navigate('/BookDetails', { state: { data: bookData } });
      if (bookData.series) {
        navigate('/book-details/you-may-also-like', { state: { data: bookData,Bookrender:bookData._id } });
      } else {
        navigate('/BookDetails', { state: { data: bookData } });
      }

  };

  const HeartFunction = async (item) => {
    const checktoken = localStorage.getItem('token');
    try {
      if (item) {
        const res = await axios.post('https://backend.mysecretlit.com/api/user/favBook', { bookId: item }, {
          headers: {
            Authorization: `Bearer ${checktoken}`,
          },
        });
        if (res.status === 200) {
          // handle success
        } else {
          console.log('Failed to favorite book. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error making API call:', error);
    }
  };

  return (
    <div className='searchbar-div'>
      <h2 className='h2-main-heading' id='height-width-searchbar'>
        Search Results for: {query}
      </h2>

      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spinner variant="danger" animation="border" />
        </div>
      ) : (
        <div className='book-div-searchbar'>
          {searchResults.length > 0 ? (
            searchResults.map((book) => (
              <div 
                key={book._id} 
                style={{ cursor: 'pointer' }} 
                className='custom-deatils-card' 
                onClick={() => handleBookClick(book)}
              >
                <BookCardTem
                  onHeartClick={() => HeartFunction(book?._id)}
                  Bookname={book?.title}
                  firstTopTag={book?.FirstTopTag}
                  secTopTag={book?.SecTopTag}
                  bookcategoriesFirst={book?.category?.name}
                  bookcategoriesSec={book?.secondaryCategory?.name}
                  rating={book?.rating}
                  bookImg={book?.coverImage}
                  loading={loading}
                />
              </div>
            ))
          ) : (
            <>
              <p>No results found for: {query}</p>
              <div style={{ paddingBottom: 50, width: '100%', display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
              <h2 className='h2-main-heading' id='height-width-searchbar' style={{textAlign:'left',width:'100%',paddingTop:10}}>
              Most Popular
      </h2>
                {topRatedBooks?.map((book) => (
                  <div 
                    key={book._id} 
                    style={{ cursor: 'pointer', paddingBottom: 20 }} 
                    className='custom-deatils-card' 
                    onClick={() => handleBookClick(book)}
                  >
                    <BookCardTem
                      onHeartClick={() => HeartFunction(book?._id)}
                      Bookname={book?.title}
                      firstTopTag={book?.FirstTopTag}
                      secTopTag={book?.SecTopTag}
                      bookcategoriesFirst={book?.category?.name}
                      bookcategoriesSec={book?.secondaryCategory?.name}
                      rating={book?.rating}
                      bookImg={book?.coverImage}
                      loading={loading}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default SearchResultsPage;
