
// import React, { useEffect, useState } from 'react'
// import BookCardTem from '../components/BookCardtem/BookCardTem'
// import './all.css'
// import { Pagination } from '@mui/material'
// import { useNavigate } from 'react-router-dom'
// import axios from 'axios'

// const Series = () => {
//   const [data, setData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 18; // Set to 18 as per your requirement
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
//   const userId = localStorage.getItem('userId')
// console.log('currentItems',currentItems)
//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//     window.scrollTo(0, 0);
//   };

//   const fetchData = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.get(`https://backend.mysecretlit.com/api/user/getSeries?userId=${userId}`);
//       const formattedData = response.data.data.map((item) => ({
//         ...item,
//         id: item._id
//       }));
//       setData(formattedData?.reverse());
     
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     fetchData();
//   }, []);

//   const navigate = useNavigate();
//   const HeartFunction = async (bookId, isFav) => {
//     const checktoken = localStorage.getItem('token');
//     try {
//       if (bookId) {
//         if (!isFav) {
//           // If the book is currently a favorite, delete it
//           const res = await axios.delete(`https://backend.mysecretlit.com/api/user/deletefavBook?bookId=${bookId}`, {
//             headers: {
//               Authorization: `Bearer ${checktoken}`,
//             },
//           });
//           if (res.status === 200) {
//             console.log('Book removed from favorites successfully');
//           } else {
//             console.log('Failed to remove book from favorites. Please try again.');
//           }
//         } else {
//           // If the book is not a favorite, add it
//           const res = await axios.post('https://backend.mysecretlit.com/api/user/favBook', {
//             bookId: bookId
//           }, {
//             headers: {
//               Authorization: `Bearer ${checktoken}`,
//             },
//           });
//           if (res.status === 200) {
//             console.log('Book added to favorites successfully');
//           } else {
//             console.log('Failed to add book to favorites. Please try again.');
//           }
//         }
//       }
//     } catch (error) {
//       console.error('Error making API call:', error);
//     }
//   };
//   return (
//     <div className='auido-book-main-div' style={{ width: '100%', background: 'black' }}>
//       <div className='main-series-div-width'>
//         <h2 className='h2-main-heading'>Series</h2>
//         <div className='seriesdiv-row'>
//           {(isLoading ? Array.from(new Array(18)) : currentItems)?.map((card, index) => (
//             <div 
//               key={index} 
//               style={{cursor:'pointer'}} 
//               className='custom-deatils-card' 
//               onClick={() => navigate('/BookDetails', { state: { data: card } })}
//             >
//               <BookCardTem
//       isFavorite={card?.isFav} // Pass the current favorite status
//       onHeartClick={(newFavStatus) => HeartFunction(card?._id, newFavStatus)} // Pass the book ID and new favorite status
//       Bookname={card?.title}
//       firstTopTag={card?.FirstTopTag}
//       secTopTag={card?.SecTopTag}
//       bookcategoriesFirst={card?.category?.name}
//       bookcategoriesSec={card?.secondaryCategory?.name}
//       rating={card?.rating}
//       bookImg={card?.coverImage}
//       loading={isLoading}
//     />
//             </div>
//           ))}
//         </div>
//         {/* Show Pagination only if there are more than 18 items */}
//         {data.length > itemsPerPage && (
//           <Pagination
//             count={Math.ceil(data.length / itemsPerPage)}
//             page={currentPage}
//             onChange={handlePageChange}
//             variant="outlined"
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// export default Series;
import React, { useEffect, useState } from 'react';
import BookCardTem from '../components/BookCardtem/BookCardTem';
import './all.css';
import { Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Series = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 18; // Set to 18 as per your requirement
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const userId = localStorage.getItem('userId');

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        window.scrollTo(0, 0);
    };

    const fetchData = async () => {
        setIsLoading(true);
        try {
            let response;
            
            // Check if userId exists
            if (userId) {
                // Call API with userId
                response = await axios.get(`https://backend.mysecretlit.com/api/user/getSeries?userId=${userId}`);
            } else {
                // Call API without userId
                response = await axios.get('https://backend.mysecretlit.com/api/user/getSeries');
            }
    
            // Format the data by mapping and adding id field
            const formattedData = response.data.data.map((item) => ({
                ...item,
                id: item._id
            }));
    console.log('series book data',formattedData)
            // Reverse the data and update state
            setData(formattedData?.reverse());
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false); // Set loading state to false after completion
        }
    };
    

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, []);

    const navigate = useNavigate();
    
    const HeartFunction = async (bookId, isFav) => {
        const checktoken = localStorage.getItem('token');
        try {
            if (bookId) {
                let updatedData;
                if (!isFav) {
                    const res = await axios.delete(`https://backend.mysecretlit.com/api/user/deletefavBook?bookId=${bookId}`, {
                        headers: {
                            Authorization: `Bearer ${checktoken}`,
                        },
                    });
                    if (res.status === 200) {
                        console.log('Book removed from favorites successfully');
                        updatedData = data.map((item) => 
                            item.id === bookId ? { ...item, isFav: false } : item
                        );
                        setData(updatedData);
                    }
                } else {
                    const res = await axios.post('https://backend.mysecretlit.com/api/user/favBook', {
                        bookId: bookId
                    }, {
                        headers: {
                            Authorization: `Bearer ${checktoken}`,
                        },
                    });
                    if (res.status === 200) {
                        console.log('Book added to favorites successfully');
                        updatedData = data.map((item) => 
                            item.id === bookId ? { ...item, isFav: true } : item
                        );
                        setData(updatedData);
                    }
                }
            }
        } catch (error) {
            console.error('Error making API call:', error);
        }
    };

    return (
        <div className='auido-book-main-div' style={{ width: '100%', background: 'black' }}>
            <div className='main-series-div-width'>
                <h2 className='h2-main-heading'>Series</h2>
                <div className='seriesdiv-row'>
                    {(isLoading ? Array.from(new Array(18)) : currentItems)?.map((card, index) => (
                        <div 
                            key={index} 
                            style={{ cursor: 'pointer' }} 
                            className='custom-deatils-card' 
                            onClick={() => navigate('/Series/book-details', { state: { data: card } })}
                        >
                            <BookCardTem
                                isFavorite={card?.books[0]?.isFav} // Pass the current favorite status
                                onHeartClick={(newFavStatus) => HeartFunction(card?.books[0]._id, newFavStatus)} // Pass the book ID and new favorite status
                                Bookname={card?.books[0]?.title}
                                firstTopTag={card?.books[0]?.FirstTopTag}
                                secTopTag={card?.books[0]?.SecTopTag}
                                bookcategoriesFirst={card?.books[0]?.primaryCategory?.name}
                                bookcategoriesSec={card?.books[0]?.secondaryCategory?.name}
                                rating={card?.books[0]?.rating}
                                bookImg={card?.books[0]?.coverImage}
                                loading={isLoading}
                            />
                        </div>
                    ))}
                </div>
                {data.length > itemsPerPage && (
                    <Pagination
                        count={Math.ceil(data.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                    />
                )}
            </div>
        </div>
    );
};

export default Series;
