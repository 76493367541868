import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SearchPage.css';  // Import custom CSS for styling
import { Spinner } from 'react-bootstrap';
import { CiSearch } from "react-icons/ci";
import { TfiLayoutLineSolid } from "react-icons/tfi";
import { RxSlash } from "react-icons/rx";
import { useNavigate } from 'react-router-dom';

const SearchPage = () => {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [topRatedbook , setTopRatedBooks] = useState([])
  const fetchBooksByrecamendebook = async () => {

    const checktoken = localStorage.getItem('token');

    try {
        const response = await axios.get('https://backend.mysecretlit.com/api/book/getTopClickedBooks?limit=10', {
            headers: {
              Authorization: `Bearer ${checktoken}`,
            },
          });
         const data = response.data?.data || [];
         
        // const booksArray = data?.map(item => item.book);
        setTopRatedBooks(data);
    } catch (error) {
        console.error('Error fetching books:', error);
    }
};
const navigate= useNavigate()
  useEffect(() => {
    fetchBooksByrecamendebook()
    const fetchBooks = async () => {
      setLoading(true);
      try {
        // Fetch books from the API
        const response = await axios.get('https://backend.mysecretlit.com/api/book/getBooks?type=book');
        const books = response.data?.data || [];

        // Filter books based on the search query (title or author)
        const filteredBooks = books.filter(book =>
          book.authorName.toLowerCase().includes(query.toLowerCase()) ||
          book.title.toLowerCase().includes(query.toLowerCase())
        );

        setSearchResults(filteredBooks);
      } catch (error) {
        console.error('Error fetching search results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBooks();
  }, [query]);
  return (
    <div className="search-page">
      {/* Custom search bar */}
      <div className="search-bar-container">
        <input
          type="text"
          className="custom-search-input"
          placeholder="Search books by title or author"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        
          <div  className='custom-spinner' style={{ position: 'absolute', }}>
          {loading ? (
            <div className='custom-spinner'>
              <Spinner size='20px'   style={{
        width: '19px',    // Adjust the width
        height: '19px',   // Adjust the height
        borderWidth: '2px' // Adjust the thickness of the spinner border
      }} variant="danger" animation="border" />
            </div>
          ) : (
            <CiSearch className="custom-spinner" style={{ fontSize: '20px', color: '#888', }} /> // Search Icon (You can replace it with any)
          )}
        </div>
      </div>

      {/* Content */}
      <div className="content">
        {query === '' ? (
          <>
            <h2>Most Popular</h2>
           {topRatedbook?.map((book)=>(
            <div key={book._id} className="book-card"  onClick={() => navigate('/BookDetails', { state: { data: book } })}>
            <img style={{width:'50px',objectFit:'cover',height:'50px'}} src={book?.coverImage}/>
              <div style={{width:'100%',textAlign:'left'}}>
              <h5 className='h5-resopinvie-sereach-bar'>{book.title}</h5>
              <div>
            <span style={{padding:0}} className='first'>{book?.category?.name}</span><span className='line-slash' style={{position:'relative'}}><RxSlash  style={{color:'white',fontSize:15,marginTop:'-2px'}}  /></span><span style={{padding:0}} className='Second'>{book?.category?.name}</span>
          </div>
              {/* <p className='paragrhg-responsive-resopinve'>{book.authorName}</p> */}
              </div>
              
            </div>
           ))}
          </>
        ) : (
          <>
           <h2>{searchResults.length} Results For  ' <span style={{color:'red'}}>{query}</span> ' </h2>
            {searchResults.length > 0 ? (
              searchResults.map((book) => (
            
                <div key={book._id} className="book-card"  onClick={() => navigate('/BookDetails', { state: { data: book } })}>
                <img style={{width:'50px',objectFit:'cover',height:'50px'}} src={book?.coverImage}/>
                  <div style={{width:'100%',textAlign:'left'}}>
                  <h5 className='h5-resopinvie-sereach-bar'>{book.title}</h5>
                  <div>
                <span style={{padding:0}} className='first'>{book?.category?.name}</span><span className='line-slash' style={{position:'relative'}}><RxSlash  style={{color:'white',fontSize:15,marginTop:'-2px'}}  /></span><span style={{padding:0}} className='Second'>{book?.category?.name}</span>
              </div>
                  {/* <p className='paragrhg-responsive-resopinve'>{book.authorName}</p> */}
                  </div>
                  
                </div>
                
              ))
            ) : (
              <p>No books found.</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchPage;
