import React from 'react'
import './Footer.css'
import { FaGripLinesVertical,FaFacebookF,FaInstagram  } from "react-icons/fa";
import Logo from '../assets/images/logo.png'
import { NavLink, useNavigate } from 'react-router-dom';
const Footer = () => {
    const naviagtion = useNavigate()
    return (
        <>
        <div className='main-footer-div'>
            <div className="termsandcondition">
                <div className="subtext"><a href='/term-and-condition' className="headingtermandconstionTex">Terms of Use</a></div>
                <div className="subtext"    > <FaGripLinesVertical style={{color:'#ADADAD',fontSize:'15px'}} /> <a href='/privacy-policy' className="headingtermandconstionTex">Privacy Policy</a></div>
                <div className="subtext"> <FaGripLinesVertical style={{color:'#ADADAD',fontSize:'15px'}} /> <a href='/Contact' className="headingtermandconstionTex">Contact Us</a></div>
            </div>
        </div>
        <div className="footer">
            <div className="rowfooter">
                <div className="colm-footer " style={{textAlign:'left'}}>
                    <img className='colm-footer-img' src={Logo} alt="" srcset=""  style={{width:'38%',cursor:'pointer'}} onClick={()=>naviagtion('/')}/>
                    <p className="paragraph-text">My Secret Lit content are all protected by Copyright Law. Any unauthorized use, sharing or duplication may be punished by copyright laws | E-mail: Contactus@mysecretlit.com</p>
                    </div>
                <div className="colm-footer main" style={{textAlign:'right',display:'flex'}}>
                    <div className="iconsdiv" style={{paddingRight:'20px'}}>
                        <FaFacebookF  className='icon-social'/>
                        <p className='paragrapgh-footer-text'>Facebook</p>
</div>
<div className='emptydiv'></div>
                    <div className="iconsdiv">
                    <FaInstagram className='icon-social' />
                    <p className='paragrapgh-footer-text'>Instagram</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Footer