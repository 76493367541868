// src/redux/categoriesSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: [
    
    {
      id: 2,
      name: 'LGPTQ+',
      products: [
        { id: 11,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/beautiful%20wedding.2e42dce77909e77785ba.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 12,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 13,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/rumana.f64b83ff8fd5cfdca65d.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 14,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 15,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 16,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 17,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 18,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 19,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 20,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
      ],
    },
    {
      id: 3,
      name: ' Sports',
      products: [
        { id: 21,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 22,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/glenna.f82c463816245c16451b.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 23,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 24,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 25,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 26,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 27,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 28,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 29,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 30,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
      ],
    },
    {
      id: 4,
      name: ' Cowboy',
      products: [
        { id: 31,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/the%20time%20travel.e0be6f8d0a2c345b9c82.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 32,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 33,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 34,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 35,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 36,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 37,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 38,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 39,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 40,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
      ],
    },
    {
      id: 5,
      name: '  Paranormal',
      products: [
        { id: 41,   Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/walking.2259a87d9ea85f1b667a.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 42,   Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 43,   Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 44,   Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 45,   Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 46,   Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 47,   Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 48,   Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 49,   Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 50,   Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
      ],
    },
    {
      id: 6,
      name: ' Sci-Fi  ',
      products: [
        { id: 51,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 52,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/beauti.0326030f3ee9eeb01958.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 53,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 54,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 55,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 56,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 57,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 58,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 59,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 60,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
      ],
    },
    {
      id: 7,
      name: 'Billionaire ',
      products: [
        { id: 61,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 62,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 63,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 64,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 65,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'  },
        { id: 66,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 67,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 68,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 69,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 70,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
      ],
    },
    {
      id: 8,
      name: 'Dark 17+ ',
      products: [
        { id: 71,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 72,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    authorName:'General Manoj Mukund Naravane' },
        { id: 73,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 74,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 75,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 76,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 77,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 78,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 79,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 80,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
      ],
    },
    {
      id: 9,
      name: 'Fairy Tale ',
      products: [
        { id: 81,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 82,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 83,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 84,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'  },
        { id: 85,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'  },
        { id: 86,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 87,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 88,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 89,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 90,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
      ],
    },
    {
      id: 10,
      name: 'Anime 18+ ',
      products: [
        { id: 91,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 92,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 93,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 94,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'  },
        { id: 95,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 96,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 97,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 98,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'  },
        { id: 99,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 100,   Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
      ],
    },
    // {
    //   id: 11,
    //   name: 'International ',
    //   products: [
    //     { id: 101,    Bookname: "The Classic Novel",
    // FirstTopTag: "Up",
    // SecTopTag: 'New',
    // bookcategoriesFirst: 'Classic',
    // bookcategoriesSec: 'Drama',
    // rating: '4.5',
    // authorName:'General Manoj Mukund Naravane' },
    //     { id: 102,    Bookname: "The Classic Novel",
    // FirstTopTag: "Up",
    // SecTopTag: 'New',
    // bookcategoriesFirst: 'Classic',
    // bookcategoriesSec: 'Drama',
    // rating: '4.5',
    // authorName:'General Manoj Mukund Naravane' },
    //     { id: 103,    Bookname: "The Classic Novel",
    // FirstTopTag: "Up",
    // SecTopTag: 'New',
    // bookcategoriesFirst: 'Classic',
    // bookcategoriesSec: 'Drama',
    // rating: '4.5',
    // authorName:'General Manoj Mukund Naravane' },
    //     { id: 104,    Bookname: "The Classic Novel",
    // FirstTopTag: "Up",
    // SecTopTag: 'New',
    // bookcategoriesFirst: 'Classic',
    // bookcategoriesSec: 'Drama',
    // rating: '4.5',
    // authorName:'General Manoj Mukund Naravane' },
    //     { id: 105,    Bookname: "The Classic Novel",
    // FirstTopTag: "Up",
    // SecTopTag: 'New',
    // bookcategoriesFirst: 'Classic',
    // bookcategoriesSec: 'Drama',
    // rating: '4.5',
    // authorName:'General Manoj Mukund Naravane' },
    //     { id: 106,    Bookname: "The Classic Novel",
    // FirstTopTag: "Up",
    // SecTopTag: 'New',
    // bookcategoriesFirst: 'Classic',
    // bookcategoriesSec: 'Drama',
    // rating: '4.5',
    // authorName:'General Manoj Mukund Naravane' },
    //     { id: 107,    Bookname: "The Classic Novel",
    // FirstTopTag: "Up",
    // SecTopTag: 'New',
    // bookcategoriesFirst: 'Classic',
    // bookcategoriesSec: 'Drama',
    // rating: '4.5',
    // authorName:'General Manoj Mukund Naravane' },
    //     { id: 108,    Bookname: "The Classic Novel",
    // FirstTopTag: "Up",
    // SecTopTag: 'New',
    // bookcategoriesFirst: 'Classic',
    // bookcategoriesSec: 'Drama',
    // rating: '4.5',
    // authorName:'General Manoj Mukund Naravane'  },
    //     { id: 109,    Bookname: "The Classic Novel",
    // FirstTopTag: "Up",
    // SecTopTag: 'New',
    // bookcategoriesFirst: 'Classic',
    // bookcategoriesSec: 'Drama',
    // rating: '4.5',
    // authorName:'General Manoj Mukund Naravane' },
    //     { id: 110,    Bookname: "The Classic Novel",
    // FirstTopTag: "Up",
    // SecTopTag: 'New',
    // bookcategoriesFirst: 'Classic',
    // bookcategoriesSec: 'Drama',
    // rating: '4.5',
    // authorName:'General Manoj Mukund Naravane'},
    //   ],
    // },
    {
      id: 12,
      name: 'Contemporary ',
      products: [
        { id: 111,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 112,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 113,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 114,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 115,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 116,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 117,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 118,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 119,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 120,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
      ],
    },
    {
      id: 13,
      name: 'Mafia ',
      products: [
        { id: 121,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'}, 
        { id: 122,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 123,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 124,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 125,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 126,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 127,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 128,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane' },
        { id: 129,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
        { id: 130,    Bookname: "The Classic Novel",
    FirstTopTag: "Up",
    SecTopTag: 'New',
    bookcategoriesFirst: 'Classic',
    bookcategoriesSec: 'Drama',
    rating: '4.5',
    bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
    authorName:'General Manoj Mukund Naravane'},
      ],
    },
    // {
    //     id: 14,
    //     name: 'Historical Fiction ',
    //     products: [
    //       { id: 131,    Bookname: "The Classic Novel",
    //   FirstTopTag: "Up",
    //   SecTopTag: 'New',
    //   bookcategoriesFirst: 'Classic',
    //   bookcategoriesSec: 'Drama',
    //   rating: '4.5',
    //   authorName:'General Manoj Mukund Naravane'}, 
    //       { id: 132,    Bookname: "The Classic Novel",
    //   FirstTopTag: "Up",
    //   SecTopTag: 'New',
    //   bookcategoriesFirst: 'Classic',
    //   bookcategoriesSec: 'Drama',
    //   rating: '4.5',
    //   authorName:'General Manoj Mukund Naravane'},
    //       { id: 133,    Bookname: "The Classic Novel",
    //   FirstTopTag: "Up",
    //   SecTopTag: 'New',
    //   bookcategoriesFirst: 'Classic',
    //   bookcategoriesSec: 'Drama',
    //   rating: '4.5',
    //   authorName:'General Manoj Mukund Naravane' },
    //       { id: 134,    Bookname: "The Classic Novel",
    //   FirstTopTag: "Up",
    //   SecTopTag: 'New',
    //   bookcategoriesFirst: 'Classic',
    //   bookcategoriesSec: 'Drama',
    //   rating: '4.5',
    //   authorName:'General Manoj Mukund Naravane' },
    //       { id: 135,    Bookname: "The Classic Novel",
    //   FirstTopTag: "Up",
    //   SecTopTag: 'New',
    //   bookcategoriesFirst: 'Classic',
    //   bookcategoriesSec: 'Drama',
    //   rating: '4.5',
    //   authorName:'General Manoj Mukund Naravane' },
    //       { id: 136,    Bookname: "The Classic Novel",
    //   FirstTopTag: "Up",
    //   SecTopTag: 'New',
    //   bookcategoriesFirst: 'Classic',
    //   bookcategoriesSec: 'Drama',
    //   rating: '4.5',
    //   authorName:'General Manoj Mukund Naravane' },
    //       { id: 137,    Bookname: "The Classic Novel",
    //   FirstTopTag: "Up",
    //   SecTopTag: 'New',
    //   bookcategoriesFirst: 'Classic',
    //   bookcategoriesSec: 'Drama',
    //   rating: '4.5',
    //   authorName:'General Manoj Mukund Naravane'},
    //       { id: 138,    Bookname: "The Classic Novel",
    //   FirstTopTag: "Up",
    //   SecTopTag: 'New',
    //   bookcategoriesFirst: 'Classic',
    //   bookcategoriesSec: 'Drama',
    //   rating: '4.5',
    //   authorName:'General Manoj Mukund Naravane' },
    //       { id: 139,    Bookname: "The Classic Novel",
    //   FirstTopTag: "Up",
    //   SecTopTag: 'New',
    //   bookcategoriesFirst: 'Classic',
    //   bookcategoriesSec: 'Drama',
    //   rating: '4.5',
    //   authorName:'General Manoj Mukund Naravane'},
    //       { id: 140,    Bookname: "The Classic Novel",
    //   FirstTopTag: "Up",
    //   SecTopTag: 'New',
    //   bookcategoriesFirst: 'Classic',
    //   bookcategoriesSec: 'Drama',
    //   rating: '4.5',
    //   authorName:'General Manoj Mukund Naravane'},
    //     ],
    //   },
      {
        id: 15,
        name: 'Thriller',
        products: [
          { id: 141,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane'}, 
          { id: 142,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane'},
          { id: 143,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 144,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 145,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 146,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 147,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane'},
          { id: 148,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 149,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane'},
          { id: 150,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane'},
        ],
      },
      {
        id: 16,
        name: 'Historical Romance ',
        products: [
          { id: 151,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane'}, 
          { id: 152,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane'},
          { id: 153,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 154,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 155,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 156,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 157,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      rating: '4.5',
      authorName:'General Manoj Mukund Naravane'},
          { id: 158,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 159,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane'},
          { id: 160,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane'},
        ],
      },
      {
        id: 17,
        name: 'Romance ',
        products: [
          { id: 151,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane'}, 
          { id: 152,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane'},
          { id: 153,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 154,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 155,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 156,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 157,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane'},
          { id: 158,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane' },
          { id: 159,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane'},
          { id: 160,    Bookname: "The Classic Novel",
      FirstTopTag: "Up",
      SecTopTag: 'New',
      bookcategoriesFirst: 'Classic',
      bookcategoriesSec: 'Drama',
      rating: '4.5',
      bookImg:'https://667a86335ae0a8d080308901--adorable-narwhal-cfccd2.netlify.app/static/media/Beast%20of.8ff0b9d712ec0c5a2411.png',
      authorName:'General Manoj Mukund Naravane'},
        ],
      },
    
    

  ],
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
});

export const selectCategories = state => state.categories.categories;
export default categoriesSlice.reducer;
