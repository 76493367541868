import React, { useEffect, useState } from 'react';
import { Pagination } from '@mui/material';
import BookCardTem from '../BookCardtem/BookCardTem';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';

const DetailspageCAta = () => {
  const { categoryname } = useParams();
  const [searchParams] = useSearchParams();
  const datacat = searchParams.get("datacat") ? JSON.parse(decodeURIComponent(searchParams.get("datacat"))) : null;
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 48; // Adjust this to 18
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (datacat && datacat?._id || datacat?.id) {
      fetchData(datacat?._id || datacat?.id);
    } else {
      console.error('No category ID found in the query parameters.');
    }
  }, [datacat._id]);

  const fetchData = async (categoryId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://backend.mysecretlit.com/api/user/getBookByCategory?categoryId=${categoryId}`);
      const formattedData = response.data?.data?.map((item, index) => ({
        ...item,
        id: item._id,
      }));
      setData(formattedData?.reverse());
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };
  const HeartFuntion = async(item)=>{
    const checktoken = localStorage.getItem('token');
    try {
        if (item) {
            const res = await axios.post('https://backend.mysecretlit.com/api/user/favBook',{bookId:item}, {
                headers: {
                    Authorization: `Bearer ${checktoken}`,
                },
            });
            if (res.status === 200) {
               
            } else {
                console.log('Failed to delete book. Please try again.');
            }
        }
    } catch (error) {
        console.error('Error making API call:', error);
    }
    }
  return (
    <div style={{ width: '100%', background: 'black' }}>
      <div className='main-series-div-width'>
        <h2 className='h2-main-heading'>{categoryname || "Hello"}</h2>
        <div className='seriesdiv-row'>
        {(isLoading ? Array.from(new Array(18)) : currentItems)?.map((card, index) => (
            <div key={index} style={{cursor:'pointer'}} className='custom-deatils-card' onClick={ () => navigate('/BookDetails', { state: { data: card } })}>
            <BookCardTem 
            onHeartClick={()=>HeartFuntion(card?._id)}
              Bookname={card?.bookTitle}
              firstTopTag={card?.FirstTopTag}
              secTopTag={card?.SecTopTag}
              bookcategoriesFirst={card?.category?.name}
              bookcategoriesSec={card?.secondaryCategory?.name}
              rating={card?.rating}
              bookImg={card?.coverImage  }
              loading={isLoading}
            />
          </div>
        ))}
        </div>
        {!isLoading && data.length > itemsPerPage && (
          <Pagination
            count={Math.ceil(data.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
          />
        )}
      </div>
    </div>
  );
};

export default DetailspageCAta;
