// src/CategoryPage.js
import React from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCategories } from '../redux/slices/Categories';
import './all.css'
import CardImg from '../assets/images/Beast of.png'
import BookCardTem from '../components/BookCardtem/BookCardTem';
function CategoryPage() {
  const { id } = useParams();
  const categories = useSelector(selectCategories);
  const category = categories.find(c => c.id.toString() === id);
console.log('category',category)
  if (!category) {
    return <div>Category not found</div>;
  }

  return (
    <div>
      <ul className='ul-classname'>
        {categories.map(category => (
          <li key={category.id}>
            <NavLink
              to={`/Genres/${category.id}`}
              className="category-link"
            activeClassName="activee"
            >
              {category.name}
            </NavLink>
          </li>
        ))}
        </ul>
      <ul className='seriesdiv-row' style={{background:'black',width:'100%',paddingBottom:20,paddingTop:20,margin:0,paddingLeft:10}}>
        {category.products.map(product => (
          // <li key={product.id}>{product.name}</li>
          <div key={product.id} className='custom-deatils-card'>
          <BookCardTem
            Bookname={product.Bookname}
            firstTopTag={product.FirstTopTag}
            secTopTag={product.SecTopTag}
            bookcategoriesFirst={product.bookcategoriesFirst}
            bookcategoriesSec={product.bookcategoriesSec}
            rating={product.rating}
            bookImg={CardImg}
          />
        </div>
        ))}
      </ul>
     
    </div>
  );
}

export default CategoryPage;
