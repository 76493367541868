import React, { useEffect, useState } from 'react';
import './Form.css'
import Modal from 'react-bootstrap/Modal';
import { RxCross1 } from "react-icons/rx";
import Google from '../../assets/images/google.png'
import Facebook from '../../assets/images/facebook.png';

const FormUserNotLogin = (props) => {
  const [activeForm, setActiveForm] = useState('signup');
  const [userData, setUserData] = useState({ email: '', password: '' });
  const handleTabClick = (tab) => {
    setActiveForm(tab);
  };
  const handleEmailChange = (event) => {
    setUserData({
      ...userData,
      email: event.target.value,
    });
  };
  const handlePasswordChange = (event) => {
    setUserData({
      ...userData,
      password: event.target.value,
    });
  };
  const handleFormSubmit = (event) => {
    // event.preventDefault();
    localStorage.setItem('user', JSON.stringify(userData));
    props.setUser(userData);
    props.onHide(); 
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div>
          <RxCross1 className='icon-btn-cross' onClick={props.onHide} />
        </div>
        <Modal.Body>
          <div className='container'>
            <div className='btn-top-button-div'>
              <button
                id='button-top-btn'
                className={activeForm === 'login' ? 'active' : ''}
                onClick={() => handleTabClick('login')}
              >
                Sign In
              </button>
              <button
                id='button-top-btn'
                className={activeForm === 'signup' ? 'active' : ''}
                onClick={() => handleTabClick('signup')}
              >
                Sign Up
              </button>
            </div>
            <form id='formFm' onSubmit={handleFormSubmit} className={activeForm === 'login' ? 'active' : ''}>
              <div>   
                <input onChange={handleEmailChange} className='input-class' type="email" id="loginEmail" name="loginEmail" required placeholder='Email' value={userData.email}  />
              </div>
              <div>
                <input onChange={handlePasswordChange} className='input-class' type="password" id="loginPassword" name="loginPassword" required placeholder='Password' value={userData.password}  />
              </div>
              <div className='div-forget'>
                <p className="singinpara"> Keep me signed in </p>
                <a href="" className="forgetpass"> Forget Password?</a>
              </div>
              <button className='btn-subit' type="submit"> Sign In</button>
            </form>
            <form id='formFm' onSubmit={handleFormSubmit} className={activeForm === 'signup' ? 'active' : ''}>
              <div>
                <input placeholder='Email' className='input-class' type="email" id="signupEmail" name="signupEmail" required />
              </div>
              <div>
                <input className='input-class' type="password" id="signupPassword" name="signupPassword" required placeholder='Password' />
              </div>
              <div className='div-forget' style={{display:'block',textAlign:'center',paddingTop:'10px'}}>
                <p className="singinpara"> By signing up, you agree to our </p>
                <a href="" className="forgetpass" style={{color:'#EE2D2F'}}> Terms of use & Privacy Policy</a>
              </div>
              <button className='btn-subit' type="submit">Sign Up</button>
            </form>
          </div>
        </Modal.Body>
           <div className="social-media">
         <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',position:'relative'}}>
         <div class="line left-line"></div>
         <p className="socialmedialine">Sign In With Social Media</p>
         <div class="line right-line"></div>
         </div>
         <div className="icons">
         <img src={Google} alt="" srcset="" className='icon-imgae' />
         <img src={Facebook} alt="" srcset="" className='icon-imgae' />    
        </div>
     </div>
   </Modal>
     </>
  );
};

export default FormUserNotLogin;