import React, { useEffect, useState } from 'react';
import './Account.css';
import Form from 'react-bootstrap/Form';
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

const CancelMember = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState('');
  const [reason, setReason] = useState('');
  const [anyMessage, setAnyMessage] = useState('');
  const [loading, setLoading] = useState(false);
console.log('as',userId)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem('user');
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        if (parsedData && parsedData.user && parsedData.user._id) {
          setUserId(parsedData.user._id);
          console.log(parsedData.user._id);
        } else {
          console.error('User data or _id is missing');
        }
      } catch (error) {
        console.error('Error parsing user data:', error);
      }
    } else {
      console.log('No data found in local storage.');
    }
  }, []);

  const handleCancelMembership = async () => {
    setLoading(true); // Set loading state to true
    const payload = {
      userId,
      reason,
      anyMessage,
    };

    try {
      const response = await axios.post('https://new-app-testing-2d30280db142.herokuapp.com/api/promos/cancel-membership', payload);
      console.log('Membership cancelled:', response.data);
      // You can add any additional logic here, such as navigating to another page or showing a success message
    } catch (error) {
      console.error('Error cancelling membership:', error);
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  return (
    <div className='recentRead-main' style={{ height: 'auto' }}>
      <div className="rowdiv-recent">
        <h1 className="recenredRed-heading">Cancellation Of Membership</h1>
        <p style={{ color: 'white', background: 'black' }} className='paragprahCencelmenber'>
          Thank you for using 'My Secret lit' <br />
          We would be very grateful if you can provide us your reason for leaving so that we can strive to improve and offer a better service...
        </p>
        <div className="infoaccount-sre">
          <p className="paragraph-account-setting" style={{ paddingBottom: 10, fontSize: 18 }}>Reasons</p>
          <div className="slect-gender-div">
            <Form.Select aria-label="Default select example" onChange={(e) => setReason(e.target.value)}>
              <option value="Quality of Content">Quality of Content</option>
              <option value="Poor Customer Service">Poor Customer Service</option>
              <option value="Payment Problems">Payment Problems</option>
              <option value="No Longer Using the Service">No Longer Using the Service</option>
              <option value="Technical Issues">Technical Issues</option>
              <option value="Difficult to Use">Difficult to Use</option>
              <option value="Change of Mind">Change of Mind</option>
              <option value="Other">Other</option>
            </Form.Select>
            <IoIosArrowDown className='icon-btn-gendrr' />
          </div>
          <p className="paragraph-account-setting" style={{ paddingBottom: 10, fontSize: 18, marginTop: 20 }}>Any message</p>
          <textarea
            placeholder="Enter your text here..."
            rows={4}
            cols={60}
            style={{ width: '100%', background: 'white', color: 'black' }}
            onChange={(e) => setAnyMessage(e.target.value)}
          />
          <div className="btn-account-setting iddas" style={{ paddingBottom: 20 }}>
            <button className='btn-account-sttig-btn' onClick={handleCancelMembership}>
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Cancel Membership'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelMember;
