import React from 'react'
import './Account.css'
const PurchaseHistory = () => {
  const data =[
    {
      date:'20-05-2000',
      plain:'1 month',
      Web:'29-09-2000'

    },
    {
      date:'20-05-2000',
      plain:'1 month',
      Web:'20-05-2000'

    }
  ]
  return (
    <div className='recentRead-main' >
    <div className="rowdiv-recent"style={{height:'60vh'}}>
    <h1 className="recenredRed-heading" >
        Purchase History
        </h1>
        <div className="mainTwn">
        <div className="purchaseHistoryMain">
          <div className="colm-pushchaseHis "> <p className="purchaseHistoryHeading purchasehistory">Date</p> </div>
          <div className="colm-pushchaseHis"><p className="purchaseHistoryHeading purchasehistory adding">Plan</p></div>
          <div className="colm-pushchaseHis"><p className="purchaseHistoryHeading purchasehistory">Renewal Date</p></div>
        </div>
        {data.length === 0 ? ( 
          <div className="emptyText-purchaseHis">
            <p className='paragrah-purchace'>
        You have no book purchase history
        </p>
          </div>
        
      ) : (
       <div>
        {data.map((item,index)=>{
          return(
 <div className="bottom-pushaceHistory-div" key={index}>
 <div className="colm-pushchaseHis"><p className="purchaseHistoryHeading">{item.date}</p></div>
   <div className="colm-pushchaseHis"><p className="purchaseHistoryHeading adding">{item.plain}</p></div>
   {/* <div className="colm-pushchaseHis"><p className="purchaseHistoryHeading">{item.Details}</p></div> */}
   <div className="colm-pushchaseHis"><p className="purchaseHistoryHeading">{item.Web}</p></div>
 </div>
          )
        })}
        </div>

      )
      }
       
        
        </div>
      </div>
      </div>
  )
}

export default PurchaseHistory